import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  root: {
    '& .MuiInputLabel-outlined:not(.MuiInputLabel-shrink)': {
      transform: 'translate(34px, 20px) scale(1);',
    },
    '&.Mui-focused .MuiInputLabel-outlined': {
      color: 'purple',
    },
  },
  inputRoot: {
    color: 'purple',
    '&[class*="MuiOutlinedInput-root"] .MuiAutocomplete-input:first-child': {
      paddingLeft: 26,
    },
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: 'green',
    },
    '&:hover .MuiOutlinedInput-notchedOutline': {
      borderColor: 'red',
    },
    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
      borderColor: 'purple',
    },
  },
  main: {
    // position: 'absolute',
    // [theme.breakpoints.up('md')]: {
    //   marginLeft: '240px',
    //   width: 'calc(100% - 240px)',
    // },
    backgroundColor: '#101012',
    // width: '100%',
    '@media (max-width: 600px)': {
      marginLeft: '-2.5rem',  // 좌측 마진을 음수로 설정하여 패딩 상쇄
      paddingLeft: '0.8rem', // 좌측 패딩을 추가하여 마진 상쇄
      marginRight: '-2.5rem', // 우측 마진을 음수로 설정하여 패딩 상쇄
      paddingRight: '0.8rem', // 우측 패딩을 추가하여 마진 상쇄
    },
  },
  pageTitle: {
    [theme.breakpoints.down('sm')]: {
      marginTop: '1.5rem',
      fontSize: '1rem',
    },
    paddingLeft: '1rem',
    fontSize: '2.5rem',
    color: '#fff',
    alignItems: 'center',
    display: 'flex',
    paddingBottom: ' 2rem',
    marginTop: '1rem',
  },
  pageSmallTitle: {
    paddingLeft: '1rem',
    fontSize: '1.5rem',
    color: '#fff',
    alignItems: 'center',
    display: 'flex',
    '@media (max-width: 600px)': {
      fontSize: '1rem',
    },
  },
  titleContainer: {
    display: 'flex',
    alignItems: 'center',
    color: '#101012',
  },
  separater: {
    borderBottomWidth: '1px',
    borderBottomColor: '#A4A4A4',
    borderBottomStyle: 'solid',
    margin: '1.5rem 0',
  },
  selected: {
    backgroundColor: '#F0F0F0!important',
    borderRadius: '0.2rem!important',
  },
  cardGrid: {

  },
  card: {
    display: 'flex',
    border: '0.1rem solid #2d2e2f',
    backgroundColor: '#1c1c1c',
    direction:"row", // 수평으로 배치
    alignItems:"center", // 수직 중앙 정렬
    justifyContent:"flex-start", // 좌측 정렬
    padding: '.7rem',
    paddingBottom: '.5rem',
    borderRadius: '0.5rem',
    color: '#fff',
    fontSize: '0.9rem',
    fontWeight: '400',
    maxWidth: '18.5rem',
    marginBottom: '0.5rem',
    marginTop: '0.5rem',
    marginLeft: '0.7rem',
    transition: 'transform 0.3s ease-in-out',
    [theme.breakpoints.down('sm')]: {
      maxWidth: '25rem',
      marginRight: '0',
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: '0.75rem',

    },
    [theme.breakpoints.down(476)]: {
      fontSize: '0.75rem',
      padding: '.4rem',
      paddingBottom: '.3rem',
      marginLeft: '0',
      height: '5.3rem',
    },
    '&:hover': {
      cursor: 'pointer',
      transform: 'scale(1.05)',
    },
  },
  cardSecondColumn: {
    flex: 'vertical',
    flexDirection: 'column', // 요소들을 수직으로 정렬
    justifyContent: 'center', // 세로 중앙 정렬
    flexGrow: 1,
  },
  cardLabelUsername: {
    alignSelf: 'flex-end',
    fontSize: '0.7rem',
    color: '#d3d3d3',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    marginBottom: '0.3rem',
    '@media (max-width: 600px)': {
      fontSize: '0.58rem',
      marginLeft: '0.5rem',
    },
  },
  cardDescription: {
    display: '-webkit-box',
    flex: 1,
    fontSize: '0.9rem',
    color: '#d3d3d3',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    WebkitLineClamp: 2, // 몇 줄을 보여줄지 설정
    WebkitBoxOrient: 'vertical',
    lineHeight: '1.0rem', // 각 줄의 높이 설정
    height: '2.4rem', // 2줄을 위한 높이 설정
    maxHeight: '2.4rem', // 2줄을 위한 최대 높이 설정은 2.4rem으로함
    marginLeft: '0.5rem',
    // marginBottom: '0.3rem',
    // [theme.breakpoints.down(476)]: {
    //   fontSize: '0.8rem',
    //   lineHeight: '0.7rem', // 작은 화면에서의 각 줄 높이 설정
    //   maxHeight: '2.0rem', // 작은 화면에서의 2줄 최대 높이 설정
    // },
    '@media (max-width: 600px)': {
      fontSize: '0.8rem',
      marginLeft: '0.5rem',
      lineHeight: '0.7rem',
      height: '2.0rem',
      maxHeight: '2.0rem',

    },
  },
  cardLabelContainer: {
    marginBottom: '0.7rem',
    marginLeft: '0.5rem',
    '@media (max-width: 600px)': {
      marginLeft: '0',
    },
  },
  cardLabel: {
    flex: 1,
    // marginBottom: '1rem', // Add some space between label and description
    display: 'flex',
    alignItems: 'top',
    fontSize: '1.1rem',
    marginTop: '0.3rem',
    flexWrap: 'wrap',
    height: 'auto',
    overflow: 'hidden',
    overflowWrap: 'anywhere',
    '@media (max-width: 600px)': {
      fontSize: '0.9rem',
      marginLeft: '0.5rem',
      marginTop: '0.3rem',
      marginBottom: '0.5rem',
    },
  },
  editIcon: {
    width: '1.2rem!important',
    marginLeft: '0.3rem',
    cursor: 'pointer',
  },
  cardCreatedAt: {
    alignSelf: 'flex-start',
    fontSize: '0.7rem',
    color: '#d3d3d3',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    // [theme.breakpoints.down(476)]: {
    //   fontSize: '0.6rem',
    // },
    '@media (max-width: 600px)': {
      fontSize: '0.6rem',
      marginLeft: '0.5rem',
    },
  },
  cardTimestamp: {
    alignItems: 'bottom',
    display: 'flex',
    marginLeft: '0.3rem',
    flex: 1,
    fontSize: '0.7rem',
    color: '#d3d3d3',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    [theme.breakpoints.down(476)]: {
      fontSize: '0.6rem',
    },
  },
  cardLikes: {
    justifyContent: 'flex-end',
    alignItems: 'center',
    fontSize: '1.2rem',
  },
  cardBottomRight: {
    justifyContent: 'flex-end',
  },
  numberPlayed: {
    color: '#d3d3d3',
    fontSize: '0.8rem',
    marginLeft: '0.3rem',
    [theme.breakpoints.down(476)]: {
      fontSize: '0.7rem',
    },
  },
  creations: {
    color: '#d3d3d3',
    fontSize: '0.6rem',
    marginLeft: '.2rem',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    marginBottom: '0.07rem',
    [theme.breakpoints.down(476)]: {
      fontSize: '0.5rem',
    },
  },
  creationsGrid: {
    alignItems: 'flex-end',
    marginBottom: '0.5rem',
  },
  cardStatus: {
    textAlign: 'center',
    marginLeft: '1rem',
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    fontSize: '0.8rem',
    [theme.breakpoints.down('xs')]: {
      maxWidth: '4rem',
      marginLeft: '0rem',
    },
  },
  cardAction: {
    cursor: 'pointer',
    width: '2.5rem',
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    paddingRight: '0.3rem',
    [theme.breakpoints.down('xs')]: {
      width: '2.5rem',
    },
  },
  tooltipImg: {
    marginLeft: '0.5rem',
    width: '1.3rem',
  },
  tooltipContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'stretch',
    [theme.breakpoints.down('xs')]: {
      padding: '1rem 0',
    },
    padding: '1rem',
    color: '#161616',
  },
  tooltipHeader: {
    alignSelf: 'center',
    fontSize: '1.5rem',
    fontWeight: '500',
    marginBottom: '1rem',
  },
  tooltipSubeaderCenter: {
    alignSelf: 'center',
    marginTop: '1.3rem',
    marginBottom: '0.8rem',
    fontSize: '1.1rem',
    fontWeight: '500',
  },
  tooltipTextContainer: {
    display: 'flex',
  },
  tooltipText: {
    [theme.breakpoints.down('xs')]: {
      fontSize: '0.9rem',
      lineHeight: '1rem',
    },
    fontSize: '1.1rem',
    fontWeight: '300',
    margin: '0.3rem 0',
    lineHeight: '1.3rem',
  },
  tooltipTextNumber: {
    marginRight: '0.2rem',
  },
  tooltipTextBullet: {
    marginLeft: '0.5rem',
    marginRight: '0.8rem',
  },
  infoIconAction: {
    width: '2.5rem',
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    paddingRight: '0.3rem',
    [theme.breakpoints.down('xs')]: {
      width: '2.5rem',
    },
  },
  infoIconImg: {
    userSelect: 'none',
    width: '1em',
    height: '1em',
    display: 'inline-block',
    fill: 'currentcolor',
    flexShrink: '0',
    transition: 'fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
    fontSize: '1.5rem',
  },
  infoIconContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'stretch',
    padding: '0.2rem',
    color: '#161616',
  },
  infoIconTextContainer: {
    flexDirection: 'column',
    alignItems: 'stretch',
    color: '#161616',
  },
  infoIconTextLine: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: '0.2rem',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  infoIconTextBold: {
    fontSize: '0.9rem',
    fontWeight: '500',
  },
  infoIconText: {
    fontSize: '0.9rem',
    fontWeight: '400',
  },
  pagination: {
    fontSize: '1.5rem',
    fontWeight: '500',
    color: '#ffffff',
    margin: '1rem 0',
  },
  imgContainer: {
    width: '4.3rem',
    height: '4.3rem',
    borderRadius: '50%',
    display: 'flex',
    alignItems: 'center',
    flexShrink: 0, // 컨테이너의 크기가 줄어들지 않도록 설정
    flexGrow: 0, // 컨테이너가 더 커지지 않도록 설정
    '@media (max-width: 600px)': {
      width: '3.5rem',
      height: '3.5rem',
      // margin: '0.2rem',
      marginRight: '0.2rem',
    },
    '@media (min-width: 601px and max-width: 960px)': {
      width: '3.5rem',
      height: '3.5rem',
    },
  },
  coverImg: {
    userSelect: 'none',
    width: '4.3rem',
    height: '4.3rem',
    display: 'inline-block',
    fill: 'currentcolor',
    flexShrink: '1',
    transition: 'fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
    fontSize: '1.5rem',
    borderRadius: '50%',
    border: '0.07rem solid #808080',
    marginRight: '0.5rem',
    '@media (max-width: 600px)': {
      width: '3.5rem',
      height: '3.5rem',

    },
    '@media (min-width: 601px and max-width: 960px)': {
      width: '3.5rem',
      height: '3.5rem',
    },
  },
  playButtonImg: {
    userSelect: 'none',
    flexShrink: '0',
    fontSize: '50px',
    cursor: 'pointer',
    borderRadius: '0.1rem',
  },
  playButtonGrid: {
    direction: 'row',
    justifyContent: 'flex-end',
  },
  likeButtonImg: {
    marginTop: '3.2rem',
    userSelect: 'none',
    display: 'inline-block',
    marginLeft: '0.5rem',
    flexShrink: '0',
    fontSize: '2px',
    cursor: 'pointer',
    borderRadius: '0.1rem',
  },
  favoriteImg: {
    userSelect: 'none',
    flexShrink: '0',
    fontSize: '2rem',
    cursor: 'pointer',
    borderRadius: '0.1rem',
  },
  favoriteFullImg: {
    userSelect: 'none',
    flexShrink: '0',
    fontSize: '2rem',
    cursor: 'pointer',
    borderRadius: '0.1rem',
    color: '#d32f2f',
  },
  numberLikes: {
    fontSize: '0.8rem',
    marginLeft: '.1rem',
    marginRight: '.1rem',
  },
  tagAutoComplete: {
    [theme.breakpoints.down('xs')]: {
      padding: '0.2rem 0.5rem!important',
      width: '100%',
    },
    '&::placeholder': {
      color: '#818181',
      fontSize: '0.9rem',
      fontWeight: '400',
      border: 'none!important',
    },
    width: '100%',
    maxWidth: '100%',
    borderRadius: '0.2rem',
    border: 'none!important',
    color: '#000',
    fontSize: '0.9rem',
    fontWeight: '400',
    outline: 'none',
    backgroundColor: '#fff',
  },
  sortSelect: {
    backgroundColor: '#101012',
    height: '2.5rem',
    [theme.breakpoints.down(1134)]: {
      width: '6',
    },
    width: '7',
    '& .MuiSelect-select': {
      color: '#fff', // Select 컴포넌트 텍스트 색상
    },
    '& .MuiMenuItem-root': {
      color: '#fff', // MenuItem 텍스트 색상
    },
    '& .MuiList-root': {
      backgroundColor: '#fff', // 메뉴 배경색 (선택 사항)
    },
    '& .MuiSelect-icon': {
      color: '#fff', // 드롭다운 토글 화살표 색상
    },
  },
  filterSortContainer: {
    [theme.breakpoints.down('s')]: {
      maxWidth: '25rem',
    },
    [theme.breakpoints.up(1135)]: {
      maxWidth: '51rem',
    },
    [theme.breakpoints.up(1550)]: {
      maxWidth: '77rem',
    },
    [theme.breakpoints.up(1965)]: {
      maxWidth: '103rem',
    },
    maxWidth: '25rem',
    display: 'flex',
    marginBottom: '1rem',
    justifyContent: 'space-between',
  },
  searchButtonContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: '3rem',
    //backgroundColor: '#2d2e2f',
    width: '100%',
  },
  recentContainer: {
    display: 'flex',
    alignItems: 'center',
    color: '#101012',
    justifyContent: 'space-between',
    marginBottom: '1rem',
    width: '97%',
  },
  searchbarContainer: {
    display: 'flex',
    alignItems: 'center',
    position: 'relative',
    width: '100%',
  },
  searchbar: {
    backgroundColor: '#2d2e2f',
    [theme.breakpoints.down('xs')]: {
      padding: '0.2rem 0.5rem!important',
      width: '100%',
    },
    '&::placeholder': {
      color: '#8c8e97',
      fontSize: '0.9rem',
      fontWeight: '400',
      border: 'none!important',
    },
    margin: '0.3rem 0 0.5rem 0',
    width: '100%',
    maxWidth: '100%',
    height: '3rem',
    borderRadius: '0.4rem',
    border: 'none!important',
    padding: '1rem',
    color: '#fff',
    fontSize: '0.9rem',
    fontWeight: '400',
    outline: 'none',
  },
  searchIcon: {
    position: 'absolute',
    right: '10px',
    color: '#818181',
  },
  button: {
    [theme.breakpoints.down('xs')]: {
      fontSize: '0.7rem',
    },
    [theme.breakpoints.down(1134)]: {
      width: '6.3rem',
      fontSize: '0.7rem',
    },
    backgroundColor: '#fff',
    margin: '.3rem 0 .5rem 0',
    width: 'auto',
    padding: '0.6rem',
    fontSize: '0.7rem',
    borderRadius: '0.2rem',
    textAlign: 'center',
    cursor: 'pointer',
    height: 'auto',
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'center',
    whiteSpace: 'nowrap',
  },
  buttonContent: {
    display: 'flex', // flex 컨테이너로 설정
    alignItems: 'center', // 수직 중앙 정렬
    justifyContent: 'center', // 수평 중앙 정렬
  },
  buttonIcon: {
    marginRight: '0.3rem',
    width: '1rem',
    height: '1rem',
    fill: 'currentColor', // SVG 아이콘 색상을 텍스트 색상과 동일하게 설정
  },
  
  filterContainer: {
    display: 'flex',
    width: '8rem',
    alignItems: 'center',
    fontSize: '1.1rem',
    textAlign: 'center',
    cursor: 'pointer',
    height: '3rem',
    justifyContent: 'center',
  },
  filterIcon: {
    color: '#818181',
    marginRight: '0.5rem',
  },
}));

export { useStyles };
