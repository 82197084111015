// src/services/socket.js
import { io } from 'socket.io-client';
import { backendBaseUrl } from '../constants/app.constants';

const socket = io(backendBaseUrl, {
  transports: ['websocket', 'polling'],
  withCredentials: true,
  reconnect: true,
});

export default socket;
