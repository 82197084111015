import React, { useState, useEffect } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { useDropzone } from 'react-dropzone';
import { useNavigate } from 'react-router-dom';
import axiosInstance from '../../services/axiosInstance';
import { useStyles } from './CreatePersonaPage.styles';
import SelectTTSVoiceModal from '../layout/SelectTTSVoiceModal';
import socket from '../../services/socket';
import LoadingModal from '../layout/LoadingModal';
import { useTranslation } from 'react-i18next'; // i18n 추가

// 이미지 업로드 부분
const FileUpload = ({ onChange, accept }) => {
  const [uploadedImage, setUploadedImage] = useState(null);

  const { getRootProps, getInputProps } = useDropzone({
    onDrop: acceptedFiles => {
      const file = acceptedFiles[0];
      setUploadedImage(URL.createObjectURL(file));
      if (onChange) {
        onChange(acceptedFiles);
      }
    },
    accept,
    multiple: false,
  });

  const classes = useStyles();

  return (
    <div className={classes.dropzoneContainer}>
      <div {...getRootProps({ className: classes.dropzone })}>
        <input {...getInputProps()} />
        {uploadedImage ? (
          <img src={uploadedImage} alt="Uploaded" className={classes.uploadedImage} />
        ) : (
          <>
            <img src="/defimg.svg" alt="Img Icon" className={classes.imgIcon} />
            <p className={classes.uploadText}>업로드 하세요</p>
          </>
        )}
      </div>
    </div>
  );
};

const CreatePersonaPage = () => {
  const classes = useStyles();
  const {
    handleSubmit,
    control,
    watch,
    formState: { errors },
    setError,
    clearErrors,
    setValue,
  } = useForm();
  const { t } = useTranslation(); // useTranslation 훅 추가
  const [error, setErrorState] = useState(null);
  const [image, setImage] = useState(null);
  const [ttsTrainingId, setTtsTrainingId] = useState(null);
  const [selectVoiceModalProps, setSelectVoiceModalProps] = useState({});
  const [shared, setShared] = useState(true);
  const [submitting, setSubmitting] = useState(false);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [loadingMessage, setLoadingMessage] = useState('');
  const [text, setText] = useState('');
  const [dialogueText, setDialogueText] = useState('');
  const [isAutoGenerateDisabled, setIsAutoGenerateDisabled] = useState(false);

  const maxLengths = {
    modelName: 50,
    description: 50,
    instruction: 10000,
    dialogueStyle: 10000,
    charInfo: 10000,
  };

  const hasReachedMaxLength = field => watch(field)?.length >= maxLengths[field];

  // 전체 진행상황을 보여주기 위해 사용하는 socket
  useEffect(() => {
    socket.on('progress', progress => {
      let translatedMessage = t(progress.message); // i18n을 사용하여 메시지 번역
      setLoadingMessage(translatedMessage);
      console.log('Progress message:', translatedMessage);
    });
    return () => {
      socket.off('progress');
    };
  }, [navigate, image, ttsTrainingId]);

  // 페르소나 설정 및 성격 자동 생성을 위한 socket
  useEffect(() => {
    socket.on('connect', () => {
      console.log('Connected to server:', socket.id);
    });

    socket.on('chunk', data => {
      // 단일 chunk 이벤트 핸들링
      if (data.type === 'profile') {
        setText(prevText => prevText + data.message.value.replace(/【.*?】/g, ''));
        clearErrors('instruction');
      } else if (data.type === 'dialogue') {
        setDialogueText(prevText => prevText + data.message.value.replace(/【.*?】/g, ''));
        clearErrors('dialogueStyle');
      }
    });

    socket.on('end', data => {
      if (data.type === 'profile') {
        let text = data.message;
        console.log('text:', text);
        setValue('instruction', text, { shouldValidate: true });
        setIsAutoGenerateDisabled(false); // 프로세스 끝나면 버튼 다시 활성화
      } else if (data.type === 'dialogue') {
        let text = data.message;
        console.log('dialogueText:', text);
        setValue('dialogueStyle', text, { shouldValidate: true });
        setIsAutoGenerateDisabled(false); // 프로세스 끝나면 버튼 다시 활성화
      }
    });

    socket.on('disconnect', () => {
      console.log('Disconnected from server');
      //여기서 에러 메시지를 띄워주는 것도 좋을 듯
      setErrorState('서버와의 연결이 끊겼습니다. 새로고침해주세요');
      //새로고침 모달 띄우기

      //페이지 새로고침
      window.location.reload();
    });

    return () => {
      socket.off('connect');
      socket.off('chunk');
      socket.off('end');
    };
  }, [text, dialogueText, clearErrors, setValue]);

  // 이미지 업로드
  const handleImageUpload = files => {
    clearErrors('image');
    setImage(files[0]);
  };

  // TTS training ID 선택
  const handleSelectVoice = () => {
    setSelectVoiceModalProps({
      open: true,
      onSelectVoice: selectedVoice => {
        clearErrors('ttsTrainingId');
        setTtsTrainingId(selectedVoice);
        setSelectVoiceModalProps({ open: false });
      },
      onSelectVoiceModalClose: () => {
        clearErrors('ttsTrainingId');
        setSelectVoiceModalProps({ open: false });
      },
    });
  };

  // 성격 자동 생성
  const handleAutoGenInstruction = () => {
    const description = watch('description');
    if (!description) {
      setError('description', {
        type: 'manual',
        message: '필수로 입력해주세요.',
      });
      return;
    }
    clearErrors('description');
    setText('');
    setIsAutoGenerateDisabled(true); // 버튼 비활성화
    socket.emit('generate', { text: description, type: 'profile' }); // 타입을 포함하여 emit
  };

  // 대화 예시 자동 생성
  const handleAutoGenDialogue = () => {
    if (!text) {
      setError('instruction', {
        type: 'manual',
        message: '필수로 입력해주세요.',
      });
      return;
    }
    clearErrors('instruction');
    setDialogueText('');
    setIsAutoGenerateDisabled(true); // 버튼 비활성화
    socket.emit('generate', { text, type: 'dialogue' });
  };

  // 챗봇 생성하기 버튼 눌렀을 때
  const onSubmit = async data => {
    let hasError = false;
    if (!image) {
      setError('image', { type: 'manual', message: '필수로 입력해주세요' });
      hasError = true;
    }
    if (!ttsTrainingId) {
      setError('ttsTrainingId', { type: 'manual', message: '필수로 입력해주세요' });
      hasError = true;
    }
    if (hasError) {
      return;
    }
    setLoading(true);
    setSubmitting(true);

    const formData = new FormData();
    if (image) {
      formData.append('image', image);
      console.log('image', image);
    }

    const jsonData = JSON.stringify({
      name: data.modelName,
      description: data.description,
      charInfo: data.charInfo,
      TtsTrainingId: ttsTrainingId.id,
      shared: shared,
      instruction: data.instruction,
      dialogueStyle: data.dialogueStyle,
      socketId: socket.id,
    });
    console.log('JsonData:', jsonData);

    formData.append('data', jsonData);
    try {
      const response = await axiosInstance.post(`/assistants`, formData);
      const assistantData = response.data.data;

      navigate('/chat', {
        state: {
          modelName: data.modelName,
          instructions: data.instructions,
          image: image ? URL.createObjectURL(image) : null,
          assistantId: assistantData.id,
          creationPageTtsTrainingId: ttsTrainingId.id,
        },
      });
    } catch (error) {
      console.error('Error creating assistant:', error);
      setErrorState(error.response?.data?.message || 'An error occurred');
    } finally {
      setLoading(false);
      setSubmitting(false);
    }
  };

  return (
    <div className={classes.main}>
      <div className={classes.headerContainer}>
        <button onClick={() => navigate(-1)} className={classes.backButton}>
          <img src="/backicon.svg" alt="Img Icon" className={classes.backIcon} />
          뒤로
        </button>

        <button type="submit" form="createPersonaForm" className={classes.submitButton} disabled={submitting}>
          등록
        </button>
      </div>
      <div className={classes.titleContainer}>
        <h2 className={classes.title}>페르소나 생성</h2>
      </div>
      <div className={classes.container}>
        <LoadingModal open={loading} message={loadingMessage} />
        <SelectTTSVoiceModal {...selectVoiceModalProps} />

        <form id="createPersonaForm" onSubmit={handleSubmit(onSubmit)} style={{ width: '100%' }}>
          <div className={classes.formGroup} style={{ display: 'flex', alignItems: 'flex-start' }}>
            <Controller
              name="image"
              control={control}
              render={({ field }) => <FileUpload {...field} onChange={handleImageUpload} accept="image/*" />}
            />
            *{errors.image && <p className={classes.errorText}>{errors.image.message}</p>}
            <div className={classes.inputContainer}>
              <label className={classes.label}>이름*</label>
              <Controller
                name="modelName"
                control={control}
                rules={{
                  required: '필수 항목입니다.',
                  maxLength: {
                    value: maxLengths.modelName,
                    message: `최대 ${maxLengths.modelName}글자까지 입력할 수 있습니다.`,
                  },
                }}
                render={({ field }) => (
                  <input
                    {...field}
                    placeholder="페르소나 모델의 이름을 입력하세요."
                    className={classes.input}
                    maxLength={maxLengths.modelName}
                  />
                )}
              />
              <div className={classes.charCountContainer}>
                <span>
                  {watch('modelName')?.length || 0}/{maxLengths.modelName}
                </span>
              </div>
              {hasReachedMaxLength('modelName') && (
                <p className={classes.errorText} style={{ color: 'red' }}>
                  더이상 입력하실 수 없습니다.
                </p>
              )}
              {errors.modelName && <p className={classes.errorText}>{errors.modelName.message}</p>}
            </div>
          </div>

          <div className={classes.ttsGroup}>
            <label className={classes.lable}>문자음성 모델 선택*</label>
            <Controller
              name="TTSVoiceModel"
              control={control}
              render={({ field }) => (
                <button type="button" onClick={handleSelectVoice} className={classes.selectButton}>
                  {ttsTrainingId ? ttsTrainingId.label : '문자음성 모델 선택'}
                </button>
              )}
            />
            {errors.ttsTrainingId && <p className={classes.errorText}>{errors.ttsTrainingId.message}</p>}
          </div>
          <div className={classes.textContainer}>
            <label className={classes.label} style={{ marginTop: '1em' }}>
              한줄 소개*{' '}
            </label>
            <Controller
              name="description"
              control={control}
              rules={{
                required: '필수 항목입니다.',
                maxLength: {
                  value: maxLengths.description,
                  message: `최대 ${maxLengths.description}글자까지 입력할 수 있습니다.`,
                },
              }}
              render={({ field }) => (
                <textarea
                  {...field}
                  placeholder="어떤 페르소나인지 간단히 설명해 주세요. 예) 햄릿은 덴마크 왕자의 복수를 다룬 셰익스피어의 비극에서 등장하는 인물 입니다."
                  className={classes.inputDescription}
                  maxLength={maxLengths.description}
                />
              )}
            />
            <div className={classes.charCountContainer}>
              <span>
                {watch('description')?.length || 0}/{maxLengths.description}
              </span>
            </div>
            {hasReachedMaxLength('description') && (
              <p className={classes.errorText} style={{ color: 'red' }}>
                더이상 입력하실 수 없습니다.
              </p>
            )}
            {errors.description && <p className={classes.errorText}>{errors.description.message}</p>}
          </div>
          <div className={classes.textContainer}>
            <div className={classes.formGroup}>
              <div className={classes.formRowSpaceBetween}>
                <label className={classes.label}>페르소나 설정 및 성격* </label>
                <button
                  type="button"
                  onClick={handleAutoGenInstruction}
                  className={`${classes.autoGenerateButton} ${isAutoGenerateDisabled ? classes.disabledButton : ''}`}
                  disabled={isAutoGenerateDisabled}
                >
                  <img src="/autogen.svg" alt="Img Icon" className={classes.imgIcon} />
                  자동 생성
                </button>
              </div>
              <Controller
                name="instruction"
                control={control}
                rules={{
                  required: '필수 항목입니다.',
                  maxLength: {
                    value: maxLengths.instruction,
                    message: `최대 ${maxLengths.instruction}글자까지 입력할 수 있습니다.`,
                  },
                }}
                render={({ field }) => (
                  <textarea
                    {...field}
                    value={text}
                    onChange={e => {
                      setText(e.target.value);
                      setValue('instruction', e.target.value, { shouldValidate: true });
                    }}
                    placeholder="해당 인물의 외모, 성격, 말투, 설정 등에 대해 입력해주세요."
                    className={classes.textarea}
                    maxLength={maxLengths.instruction}
                  />
                )}
              />
              <div className={classes.charCountContainer}>
                <span>
                  {watch('instruction')?.length || 0}/{maxLengths.instruction}
                </span>
              </div>
              {hasReachedMaxLength('instruction') && (
                <p className={classes.errorText} style={{ color: 'red' }}>
                  더이상 입력하실 수 없습니다.
                </p>
              )}
              {errors.instruction && <p className={classes.errorText}>{errors.instruction.message}</p>}
            </div>
          </div>
          <div className={classes.textContainer}>
            <div className={classes.formGroup}>
              <div className={classes.formRowSpaceBetween}>
                <label className={classes.label}>대화 예시* </label>
                <button
                  type="button"
                  onClick={handleAutoGenDialogue}
                  className={`${classes.autoGenerateButton} ${isAutoGenerateDisabled ? classes.disabledButton : ''}`}
                  disabled={isAutoGenerateDisabled}
                >
                  <img src="/autogen.svg" alt="Img Icon" className={classes.imgIcon} />
                  자동 생성
                </button>
              </div>
              <Controller
                name="dialogueStyle"
                control={control}
                rules={{
                  required: '필수 항목입니다.',
                  maxLength: {
                    value: maxLengths.dialogueStyle,
                    message: `최대 ${maxLengths.dialogueStyle}글자까지 입력할 수 있습니다.`,
                  },
                }}
                render={({ field }) => (
                  <textarea
                    {...field}
                    value={dialogueText}
                    onChange={e => {
                      setDialogueText(e.target.value);
                      setValue('dialogueStyle', e.target.value, { shouldValidate: true });
                    }}
                    placeholder="해당 인물의 대사와 그 대사가 사용되는 맥락을 입력해주세요."
                    className={classes.textarea}
                    maxLength={maxLengths.dialogueStyle}
                  />
                )}
              />
              <div className={classes.charCountContainer}>
                <span>
                  {watch('dialogueStyle')?.length || 0}/{maxLengths.dialogueStyle}
                </span>
              </div>
              {hasReachedMaxLength('dialogueStyle') && (
                <p className={classes.errorText} style={{ color: 'red' }}>
                  더이상 입력하실 수 없습니다.
                </p>
              )}
              {errors.dialogueStyle && <p className={classes.errorText}>{errors.dialogueStyle.message}</p>}
            </div>
          </div>
          <div className={classes.textContainer}>
            <div className={classes.formGroup}>
              <label className={classes.label}>상세 정보* </label>
              <Controller
                name="charInfo"
                control={control}
                rules={{
                  required: '필수 항목입니다.',
                  maxLength: {
                    value: maxLengths.charInfo,
                    message: `최대 ${maxLengths.charInfo}글자까지 입력할 수 있습니다.`,
                  },
                }}
                render={({ field }) => (
                  <textarea
                    {...field}
                    placeholder="해당 인물과 관련된 모든 정보를 입력해주세요. (위키 내용 등)"
                    className={classes.textarea}
                    maxLength={maxLengths.charInfo}
                  />
                )}
              />
              <div className={classes.charCountContainer}>
                <span>
                  {watch('charInfo')?.length || 0}/{maxLengths.charInfo}
                </span>
              </div>
              {hasReachedMaxLength('charInfo') && (
                <p className={classes.errorText} style={{ color: 'red' }}>
                  더이상 입력하실 수 없습니다.
                </p>
              )}
              {errors.charInfo && <p className={classes.errorText}>{errors.charInfo.message}</p>}
            </div>
          </div>
          <div className={classes.sharedGroup}>
            <label className={classes.label}>커뮤니티 공개여부*</label>
            <div className={classes.toggleContainer}>
              <div className={classes.toggleButtonWrapper}>
                <div className={`${classes.toggleButton} ${shared ? 'active' : ''}`} onClick={() => setShared(true)}>
                  공개
                </div>
                <div className={`${classes.toggleButton} ${!shared ? 'active' : ''}`} onClick={() => setShared(false)}>
                  비공개
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default CreatePersonaPage;
