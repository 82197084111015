import plusImg from '../../../img/drawer-plus.png';
import fileImg from '../../../img/drawer-file.png';
import questionImg from '../../../img/question_mark.png';
import supportImg from '../../../img/supportIcon.png';
import shareImg from '../../../img/share.png';
import betaImg from '../../../img/beta.png';

export const getDrawerItems = t => {
  return {
    sectionProduction: [{ text: t('sectionProduction.0') }],
    sectionOne: [
      { text: t('inferenceTab.title'), icon: plusImg, to: 'generate-music' },
      { text: t('trainingTab.title'), icon: plusImg, to: 'train-voice' },
      {
        text: t('vocalExtractorTab.title'),
        icon: plusImg,
        to: 'vocal-extract',
      },
      {
        text: t('communityTab.title'),
        icon: shareImg,
        to: 'community',
      },
    ],
    sectionTwo: [
      { text: t('historyTab.title'), icon: fileImg, to: 'my-voices' },
      { text: t('subscriptionTab.title'), icon: fileImg, to: 'subscription' },
    ],
    sectionThree: [
      { text: t('faqTab.title'), icon: questionImg, to: 'faq' },
      { text: t('contactTab.title'), icon: supportImg, to: 'contact' },
    ],
    sectionBeta: [{ text: t('ttsAppBar.sectionTTS') }],
    sectionSubBeta: [
      { text: t('ttsAppBar.subSectionTTS.0'), icon: plusImg, to: 'generate-tts' },
      { text: t('ttsAppBar.subSectionTTS.1'), icon: plusImg, to: 'tts-train-voice' },
      { text: t('ttsAppBar.subSectionTTS.2'), icon: fileImg, to: 'tts-history' },
    ],

    sectionPersona: [{ text: t('personaBar.sectionPersona'), icon: betaImg, to: 'persona-community' }],
  };
};
