import { makeStyles } from '@material-ui/core/styles';
export const drawerWidth = 240;
const useStyles = makeStyles(theme => ({
  main: {
    position: 'absolute',
    [theme.breakpoints.up('md')]: {
      marginLeft: '240px',
      width: 'calc(100% - 240px)',
    },
    top: '65px',
    backgroundColor: '#101012',
    width: '100%',
    padding: '0 2.5rem',
    marginLeft: '0px',
  },
  layout: {},
}));

export { useStyles };
