export const appConstants = {
  OPEN_LOGIN_MODAL: 'OPEN_LOGIN_MODAL',
  CLOSE_LOGIN_MODAL: 'CLOSE_LOGIN_MODAL',
  OPEN_MESSAGE_MODAL: 'OPEN_MESSAGE_MODAL',
  CLOSE_MESSAGE_MODAL: 'CLOSE_MESSAGE_MODAL',
  OPEN_DOWNLOAD_MODAL: 'OPEN_DOWNLOAD_MODAL',
  CLOSE_DOWNLOAD_MODAL: 'CLOSE_DOWNLOAD_MODAL',
  OPEN_LANGUAGE_MODAL: 'OPEN_LANGUAGE_MODAL',
  CLOSE_LANGUAGE_MODAL: 'CLOSE_LANGUAGE_MODAL',
  SET_INITIALIZED: 'SET_INITIALIZED',
  SELECT_COMMUNITY_ITEM: 'SELECT_COMMUNITY_ITEM',
  CLEAR_SELECTED_COMMUNITY_ITEM: 'CLEAR_SELECTED_COMMUNITY_ITEM',
};

//export const backendBaseUrl = 'http://localhost:80';
//여긴 커밋 금지
//export const backendBaseUrl = 'https://api.sorisori.ai';
export const backendBaseUrl = 'https://api-persona-beta.sorisori.ai';
