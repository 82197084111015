import React from 'react';
import { useStyles } from './ChatMessage.styles';
import PlayCircleFilledWhiteIcon from '@material-ui/icons/PlayCircleFilledWhite';

const ChatMessage = ({ message, image, modelName, handlePlayButtonClick, isPlaying, isSendBlocked }) => {
  const classes = useStyles();
  const isUser = message.role === 'user';

  return (
    <div className={isUser ? classes.userMessageContainer : classes.assistantMessageContainer}>
      {!isUser && (
        <>
          <img src={image} alt={modelName} className={classes.avatar} />
          <div className={classes.assistantMessageWrapper}>
            <div className={classes.header}>
              <span className={classes.modelName}>{modelName}</span>
              <button onClick={handlePlayButtonClick} className={classes.playButton} disabled={isPlaying || isSendBlocked}>
                {isPlaying ? (
                  <div className={classes.bars}>
                    <div className={classes.bar}></div>
                    <div className={classes.bar}></div>
                    <div className={classes.bar}></div>
                  </div>
                ) : (
                  <img src='/playButton.svg' alt='Play Icon' className={classes.playIcon} />
                )}
              </button>
            </div>
            <div className={classes.assistantMessage}>{message.content}</div>
          </div>
        </>
      )}
      {isUser && <div className={classes.userMessage}>{message.content}</div>}
    </div>
  );
};

export default ChatMessage;
