import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useStyles } from './PersonaHistory.styles.js';
import moment from 'moment';
import Grid from '@material-ui/core/Grid';
import InfiniteScroll from 'react-infinite-scroll-component';
import { appActions } from '../../actions/app.actions.js';
import CommunityUploadModal from '../layout/CommunityUploadModal';

function PersonaHistory({ userThreads }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [data, setData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);

  const classes = useStyles();

  useEffect(() => {
    setData(userThreads);
    setFilteredData(userThreads);
  }, [userThreads]);

  return (
    <>
      <CommunityUploadModal />
      <div className={classes.pageTitle}>{t('최근 대화')}</div>

      <Grid
        container
        item
        xs={12}
        sm={12}
        md={12}
        display="flex"
        justifyContent="flex-start"
        style={{ marginTop: '1rem' }}
      ></Grid>

      <div className={classes.scrollContainer}>
        <InfiniteScroll
          className={classes.infiniteScroll}
          dataLength={filteredData.length}
          hasMore={false}
          horizontal={true}
          loader={
            <div className={classes.pagination} key={0}>
              {t('historyTab.pagination.loader')}
            </div>
          }
          scrollableTarget="scrollableDiv"
        >
          <div className={classes.cardGrid}>
            {filteredData.map(
              ({ id: threadId, updatedAt, assistant, ttsTrainingId, lastMessage }) => {
                const assistantId = assistant?.id;
                const assistantImage = assistant?.image;
                const assistantModelName = assistant?.name;
                return (
                  <div
                    className={classes.card}
                    key={threadId}
                    onClick={() => {
                      navigate('/chat', {
                        state: {
                          modelName: assistantModelName,
                          image: assistantImage,
                          assistantId: assistantId,
                          threadId: threadId,
                          ttsTrainingId: ttsTrainingId,
                        },
                      });
                    }}
                  >
                    <div className={classes.cardHeader}>
                      {assistantImage && (
                        <img className={classes.coverImg} src={assistantImage} alt="cover-img" />
                      )}
                      <div className={classes.cardLabel}>{assistantModelName}</div>
                      <div className={classes.cardUpdatedAt}>{moment(updatedAt).fromNow()}</div>
                    </div>
                    <div className={classes.cardSecondColumn}>
                      <div className={classes.cardLastMessage}>{lastMessage}</div>
                    </div>
                  </div>
                );
              }
            )}
          </div>
        </InfiniteScroll>
      </div>
    </>
  );
}

export default PersonaHistory;
