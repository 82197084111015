import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';

import { useDropzone } from 'react-dropzone';
import AudioPlayer from 'material-ui-audio-player';
import ReactJkMusicPlayer from 'react-jinke-music-player';
import 'react-jinke-music-player/assets/index.css';
import HtmlTooltip from '../HtmlTooltip';

import Grid from '@material-ui/core/Grid';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import DeleteIcon from '@material-ui/icons/Delete';
import CircularProgress from '@material-ui/core/CircularProgress';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import Button from '@material-ui/core/Button';

import {
  fetchSubscriptionInfo,
  getYoutubeInfo,
  checkVocalExtractCompletion,
  uploadVocalExtract,
  checkVocalExtractCompletionById,
  getVocalExtractSignedUrl,
} from '../../services/page.services';
import { useStyles } from './VocalExtractorPage.styles';
import { dragAndDropStyle } from './shared.styles';
import { checkValidLoginStatus } from '../../utils/user.utils';
import { appActions } from '../../actions/app.actions.js';
import { userActions } from '../../actions/user.actions.js';

function VocalExtractorPage() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const user = useSelector(state => state.user);
  const userId = user?.id;
  const subscriptionTypes = ['PREMIUM', 'PRO', 'ADMIN', 'CUSTOM'];

  const intervalIDRef = React.useRef(null);
  const [loadingText, setLoadingText] = useState('');
  const [alignment, setAlignment] = React.useState('upload');
  const [vocalExtractRemainingCount, setVocalExtractRemainingCount] = useState();
  const [vocalExtractInProgress, setVocalExtractInProgress] = useState(false);
  const [loading, setLoading] = useState(false);
  const [content, setContent] = useState({});

  const [completedAudio, setCompletedAudio] = React.useState();
  const [outputFormat, setOutputFormat] = React.useState('mp3');
  const [showOutputFormatTooltip, setShowOutputFormatTooltip] = useState(false);

  useEffect(() => () => intervalIDRef.current && clearInterval(intervalIDRef.current), []);

  useEffect(() => {
    if (userId) (async () => initPage())();
  }, [userId]);

  const checkVocalExtractInProgress = async () => {
    let vocalExtract = await checkVocalExtractCompletion();
    const inProgress = _.some(vocalExtract, { status: 'PENDING' });
    setVocalExtractInProgress(inProgress);
  };

  const getBlobFromS3Url = async url => {
    const res = await fetch(url);
    return res.blob();
  };

  const initPage = async () => {
    try {
      await checkVocalExtractInProgress();
      await checkRemainingCount();
    } catch (e) {
      dispatch(appActions.openMessageModal(t('modal.pageLoadFail'), true));
    }
  };

  const checkRemainingCount = async () => {
    let subscriptionInfo = await fetchSubscriptionInfo();
    dispatch(userActions.updateSubscription(subscriptionInfo));
    setVocalExtractRemainingCount(subscriptionInfo?.vocalExtractRemaining || 0);
    return subscriptionInfo;
  };

  const onDropAccepted = async acceptedFiles => {
    if (checkValidLoginStatus(userId, dispatch) && acceptedFiles.length > 0) {
      const file = acceptedFiles[0];
      setContent({ ...content, file, name: file.name });
    }
  };
  const onDropRejected = async input => {
    if (checkValidLoginStatus(userId, dispatch)) {
      const message = (() => {
        switch (input[0].errors[0].code) {
          case 'file-invalid-type':
            return t('vocalExtractorTab.modal.notSupportedFileType');
          case 'too-many-files':
            return t('vocalExtractorTab.modal.tooManyFiles');
          case 'file-too-large':
            return t('vocalExtractorTab.modal.exceedMaxSize');
        }
      })();
      if (message) dispatch(appActions.openMessageModal(message));
    }
  };

  const { getRootProps, getInputProps } = useDropzone({
    accept: {
      'audio/mp3': ['.mp3'],
      'audio/wav': ['.wav'],
      'audio/mpeg-4': ['.m4a'],
      'audio/flac': ['.flac'],
      'audio/ogg': ['.ogg'],
      // "video/mpeg-4":[".mp4"],
      // "video/x-matroska ":[".mkv"],
    },
    maxFiles: 1,
    onDropAccepted,
    onDropRejected,
    disabled: loadingText,
    maxSize: 78643200,
  });

  const checkCompleteForm = () => {
    let message = '';
    if (vocalExtractInProgress) {
      message = t('vocalExtractorTab.modal.inProgress');
    } else if (vocalExtractRemainingCount <= 0) {
      message = t('vocalExtractorTab.modal.noRemaining');
    } else if (!content.file && !content.youtubeInfo) {
      message = t('vocalExtractorTab.modal.emptyYoutubeLink');
    } else if (!content.label) {
      message = t('vocalExtractorTab.modal.noLabel');
    }
    if (message) {
      dispatch(appActions.openMessageModal(message));
      return false;
    }
    return true;
  };

  const checkYoutubeLink = async () => {
    setLoading(true);
    try {
      const { duration, valid, name } = await getYoutubeInfo(content.youtubeLink);
      if (!valid) {
        setContent({ ...content, youtubeLink: '', name: null });
        dispatch(appActions.openMessageModal(t('vocalExtractorTab.modal.invalidYoutubeLink')));
      } else if (duration > 600) {
        setContent({ ...content, youtubeLink: '', name: null });
        dispatch(appActions.openMessageModal(t('vocalExtractorTab.modal.youtubeLinkTooLong')));
      } else {
        setContent({
          ...content,
          youtubeInfo: { duration, name },
          name,
        });
      }
    } catch (e) {
      dispatch(appActions.openMessageModal(t('vocalExtractorTab.submit.tryAgain')));
    } finally {
      setLoading(false);
    }
  };

  const setUploadType = uploadType => {
    setAlignment(uploadType);
    setContent({
      ...content,
      youtubeLink: '',
      file: null,
      youtubeInfo: null,
      name: null,
    });
  };

  const handleSubmit = async e => {
    if (!checkCompleteForm()) return;
    setCompletedAudio(null);
    setLoadingText(t('vocalExtractorTab.submit.uploading'));
    let vocalExtract;
    try {
      vocalExtract = await uploadVocalExtract(userId, content, outputFormat);
    } catch (e) {
      dispatch(appActions.openMessageModal(t('vocalExtractorTab.submit.tryAgain' + e)));
      setLoadingText('');
      return;
    }
    setLoadingText(t('vocalExtractorTab.submit.inProgress'));

    const tick = async () => {
      const id = vocalExtract.id;

      const voiceFileName = `${id}-voice.${vocalExtract.outputFormat}`;
      const instrumentalFileName = `${id}-instrument.${vocalExtract.outputFormat}`;
      const backVocalFileName = `${id}-backvocal.${vocalExtract.outputFormat}`;
      try {
        const status = await checkVocalExtractCompletionById(id);
        if (status === 'COMPLETE') {
          clearInterval(intervalIDRef.current);
          const voiceUrl = await getVocalExtractSignedUrl(id, voiceFileName);
          const backVocalUrl = await getVocalExtractSignedUrl(id, backVocalFileName);
          const instrumentalUrl = await getVocalExtractSignedUrl(id, instrumentalFileName);
          const voiceBlob = await getBlobFromS3Url(voiceUrl);
          const backVocalBlob = await getBlobFromS3Url(backVocalUrl);
          const instrumentalBlob = await getBlobFromS3Url(instrumentalUrl);

          await checkRemainingCount();
          setLoadingText('');
          setCompletedAudio([
            {
              name: `${content.label}-voice`,
              musicSrc: URL.createObjectURL(voiceBlob.slice(0, voiceBlob.size, `audio/${vocalExtract.outputFormat}`)),
              extension: vocalExtract.outputFormat,
            },
            {
              name: `${content.label}-instrument`,
              musicSrc: URL.createObjectURL(
                instrumentalBlob.slice(0, instrumentalBlob.size, `audio/${vocalExtract.outputFormat}`)
              ),
              extension: vocalExtract.outputFormat,
            },
            {
              name: `${content.label}-backvocal`,
              musicSrc: URL.createObjectURL(
                backVocalBlob.slice(0, backVocalBlob.size, `audio/${vocalExtract.outputFormat}`)
              ),
              extension: vocalExtract.outputFormat,
            },
          ]);
        } else if (status === 'FAILED') {
          clearInterval(intervalIDRef.current);
          setLoadingText('');
        }
      } catch (e) {}
    };
    intervalIDRef.current = setInterval(tick, 5000);
  };

  const customDownloader = downloadInfo => {
    const downloadingAudio = _.find(completedAudio, {
      musicSrc: downloadInfo.src,
    });
    const link = document.createElement('a');
    link.href = downloadInfo.src;
    link.download = `${downloadingAudio.name}.${downloadingAudio.extension}`;
    document.body.appendChild(link);
    link.click();
  };

  const onBeforeDestroy = () => {
    return new Promise((_resolve, reject) => {
      setCompletedAudio(null);
      reject();
    });
  };

  const handleWavButtonClick = () => {
    if (checkValidLoginStatus(userId, dispatch)) {
      if (!subscriptionTypes.includes(user.subscription?.type)) {
        setShowOutputFormatTooltip(true);
      } else {
        setOutputFormat('wav');
        setShowOutputFormatTooltip(false);
      }
    }
  };

  const classes = useStyles();
  return (
    <div>
      <div className={classes.pageTitle}>{t('vocalExtractorTab.title')}</div>
      <div className={classes.separater} />
      <Grid container spacing={3}>
        <Grid item xs={12} sm={12} md={12}>
          <div className={classes.stepHeader}>{t('vocalExtractorTab.stepOne.title')}</div>
          <div className={classes.stepSubHeader}>{t('vocalExtractorTab.stepOne.vocalExtractorSubTitles.0')}</div>
          <ButtonGroup className={classes.inputSourceButtonContainer}>
            <Button
              className={`${classes.inputSourceButton} ${alignment == 'upload' ? classes.selected : ''}`}
              onClick={() => setUploadType('upload')}
            >
              {t('vocalExtractorTab.stepOne.tabs.fileUpload.title')}
            </Button>
            <Button
              className={`${classes.inputSourceButton} ${alignment == 'youtube' ? classes.selected : ''}`}
              onClick={() => setUploadType('youtube')}
            >
              {t('vocalExtractorTab.stepOne.tabs.youtube.title')}
            </Button>
          </ButtonGroup>
          {alignment === 'upload' && (
            <>
              {!content.file && (
                <div className={classes.recordButtonContainer}>
                  <div {...getRootProps({ style: dragAndDropStyle })}>
                    <input {...getInputProps()} />
                    <div className={classes.dragAndDrop}>
                      <div className={classes.dragAndDropText}>
                        {t('vocalExtractorTab.stepOne.tabs.fileUpload.dragAndDropText')}
                        {t('vocalExtractorTab.stepOne.tabs.fileUpload.dragAndDropDuration')}
                      </div>
                      <div className={classes.dragAndDropButton}>
                        {t('vocalExtractorTab.stepOne.tabs.fileUpload.dragAndDropButton')}
                      </div>
                    </div>
                  </div>
                </div>
              )}

              {content.file && (
                <div className={classes.audioPlayerContainer}>
                  <AudioPlayer
                    icons={{ CloseIcon: DeleteIcon }}
                    useStyles={useStyles}
                    onClose={() => setContent({ ...content, file: null, name: null })}
                    displayCloseButton
                    volume={false}
                    src={URL.createObjectURL(content.file)}
                  />
                </div>
              )}
            </>
          )}
          {alignment === 'youtube' && (
            <div className={classes.recordButtonContainer}>
              {content.youtubeInfo ? (
                <div className={classes.youtubeInfoContainer}>
                  <div className={classes.youtubeInfo}>
                    {content.youtubeInfo.name} ({Math.floor(content.youtubeInfo.duration / 60)}
                    {t('vocalExtractorTab.stepOne.tabs.youtube.durationUnit.0')}
                    {content.youtubeInfo.duration % 60}
                    {t('vocalExtractorTab.stepOne.tabs.youtube.durationUnit.1')}
                    )
                    <DeleteIcon
                      className={classes.youtubeInfoDeleteIcon}
                      onClick={() =>
                        setContent({
                          ...content,
                          youtubeInfo: null,
                          youtubeLink: '',
                          name: null,
                        })
                      }
                    />
                  </div>
                </div>
              ) : (
                <>
                  <OutlinedInput
                    placeholder={t('vocalExtractorTab.stepOne.tabs.youtube.linkPlaceholder')}
                    onChange={e => setContent({ ...content, youtubeLink: e.target.value })}
                    value={content.youtubeLink}
                    className={classes.youtubeLinkInput}
                  />
                  <Button
                    // defining style here because for some reason some styles
                    // doesn't get applied correctly on production
                    style={{
                      backgroundColor: '#242A2D',
                      borderTopLeftRadius: 0,
                      borderBottomLeftRadius: 0,
                      borderTopRightRadius: 9,
                      borderBottomRightRadius: 9,
                      boxShadow: 'none',
                      width: '7.5rem',
                      cursor: 'pointer',
                      color: '#fff',
                    }}
                    disabled={!content.youtubeLink || loading}
                    onClick={checkYoutubeLink}
                  >
                    {loading ? (
                      <CircularProgress
                        // defining style here because for some reason some styles
                        // doesn't get applied correctly on production
                        style={{ width: '1rem', height: '1rem' }}
                      />
                    ) : (
                      <div className={classes.buttonText}>{t('vocalExtractorTab.stepOne.tabs.youtube.linkbutton')}</div>
                    )}
                  </Button>
                </>
              )}
            </div>
          )}
        </Grid>
        <Grid item xs={12} sm={12} md={12}>
          <div className={`${classes.separater} ${classes.noMargin}`} />
        </Grid>
        <Grid item xs={12} sm={12} md={12}>
          <div className={classes.stepHeader}>{t('vocalExtractorTab.stepTwo.title')}</div>
        </Grid>
        <Grid item xs={12} sm={12} md={6}>
          <OutlinedInput
            onChange={e => {
              if (checkValidLoginStatus(userId, dispatch)) {
                setContent({ ...content, label: e.target.value });
              }
            }}
            placeholder={t('vocalExtractorTab.stepTwo.labelInput.placeHolder')}
            style={{
              width: '100%',
              backgroundColor: '#fff',
            }}
            disabled={loading}
          />
        </Grid>
        <Grid style={{ marginBottom: '0.5rem' }} item xs={12} sm={12} md={12}>
          <div className={classes.stepSubHeader}>{t('vocalExtractorTab.stepTwo.ouputFormatLabel')}</div>
          <ButtonGroup className={classes.inputSourceButtonContainer}>
            <Button
              className={`${classes.inputSourceButton} ${outputFormat == 'mp3' ? classes.selected : ''}`}
              onClick={() => setOutputFormat('mp3')}
            >
              {'MP3'}
            </Button>
            <HtmlTooltip
              title={
                <div className={classes.tooltipContainer}>
                  <div className={classes.tooltipTextContainer}>
                    <div className={classes.tooltipText}>
                      {t('vocalExtractorTab.stepTwo.tooltip.outputFormatMessage')}
                    </div>
                  </div>
                  <div className={classes.tooltipTextContainer}>
                    <Link to="/subscription" className={classes.tooltipTextLink}>
                      {user.subscription?.type === 'BASIC'
                        ? t('vocalExtractorTab.stepTwo.tooltip.upgradePrompt')
                        : t('vocalExtractorTab.stepTwo.tooltip.subscribePrompt')}
                    </Link>
                  </div>
                </div>
              }
              interactive
              leaveDelay={200}
              open={showOutputFormatTooltip && !subscriptionTypes.includes(user.subscription?.type)}
              onClose={() => setShowOutputFormatTooltip(false)}
            >
              <Button
                className={`${classes.inputSourceButton} ${outputFormat === 'wav' ? classes.selected : ''}`}
                onClick={handleWavButtonClick}
              >
                {'WAV'}
              </Button>
            </HtmlTooltip>
          </ButtonGroup>
        </Grid>
        <Grid className={classes.buttonGrid} item xs={12} sm={12} md={12}>
          {!loadingText ? (
            <div className={classes.buttonContainer}>
              <div className={classes.button} onClick={handleSubmit}>
                {vocalExtractInProgress
                  ? t('vocalExtractorTab.submit.alreadyInProgress')
                  : userId && vocalExtractRemainingCount <= 0
                    ? t('vocalExtractorTab.submit.noRemaining')
                    : t('vocalExtractorTab.submit.start')}
              </div>
              {userId &&
                (user.subscription?.status === 'past_due' || user.subscription?.type !== 'PREMIUM') &&
                vocalExtractRemainingCount > 0 && (
                  <div className={classes.buttonRemainingText}>
                    {t('vocalExtractorTab.submit.remainingCountText')}: {vocalExtractRemainingCount}
                    {t('vocalExtractorTab.submit.remainingCountUnit')}
                  </div>
                )}
            </div>
          ) : (
            <div className={classes.laodingContainer}>
              <CircularProgress size="1.4rem" />
              <span className={classes.laodingText}>{loadingText}</span>
            </div>
          )}
        </Grid>
        {completedAudio && (
          <ReactJkMusicPlayer
            mode="full"
            theme="light"
            autoPlay={false}
            showPlayMode={false}
            showThemeSwitch={false}
            showMiniModeCover={false}
            autoHiddenCover
            onBeforeDestroy={onBeforeDestroy}
            spaceBar={true}
            showDestroy
            responsive={false}
            showReload={false}
            toggleMode={false}
            remove={false}
            customDownloader={customDownloader}
            audioLists={completedAudio}
          />
        )}
      </Grid>
    </div>
  );
}
export default VocalExtractorPage;
