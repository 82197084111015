import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(theme => ({
  container: {
    maxWidth: '80%',
    margin: '0 auto',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    color: '#ffffff', // 전체 텍스트 색상을 하얀색으로 변경
    // 작은 화면(모바일)일 경우
    '@media (max-width: 600px)': {
      maxWidth: '100%',
      margin: '0',
    },
  },
  main: {
    '@media (max-width: 600px)': {
      marginLeft: '-2.5rem',  // 좌측 마진을 음수로 설정하여 패딩 상쇄
      paddingLeft: '1rem', // 좌측 패딩을 추가하여 마진 상쇄
      marginRight: '-2.5rem', // 우측 마진을 음수로 설정하여 패딩 상쇄
      paddingRight: '1rem', // 우측 패딩을 추가하여 마진 상쇄
    },
    marginLeft: '0px',
  },
  uploadText: {
    '@media (max-width: 600px)': {
      fontSize: '0.8em',
    },
  },
  inputContainer: {
    marginLeft: '5%',
    flex: 1,
    marginBottom: '4em',
  },
  textContainer: {
    flex: 1,
    marginBottom: '3em',
  },
  headerContainer: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: '1em',
  },
  backButton: {
    padding: '0.5em 1em',
    fontSize: '1em',
    borderRadius: '0.4em',
    border: '1px solid #101012',
    backgroundColor: '#101012',
    color: '#fff',
    cursor: 'pointer',
    fontWeight: 'bold', // 폰트를 볼드체로 설정
  },
  submitButton: {
    padding: '0.8em 3.6em',
    fontSize: '0.8em',
    borderRadius: '0.4em',
    border: 'none',
    backgroundColor: '#2d2e2f',
    color: '#82848c',
    cursor: 'pointer',
  },
  titleContainer: {
    maxWidth: '80%',

    margin: '0 auto',
    // 작은 화면(모바일)일 경우
    '@media (max-width: 600px)': {
      maxWidth: '80%',
      marginLeft: '0.6em',
    },
  },
  title: {
    fontSize: '2em',
    fontWeight: 'bold',
    // marginBottom: '1em',
    alignSelf: 'flex-start',
    color: '#ffffff', // 제목 텍스트 색상을 하얀색으로 변경
    // 작은 화면(모바일)일 경우
    '@media (max-width: 600px)': {
      fontSize: '1em',
    },

    // 큰 화면일 경우
    '@media (min-width: 601px)': {
      fontSize: '2em',
    },
  },
  charCountContainer: {
    textAlign: 'right', // 우측 정렬
    color: '#ffffff', // 텍스트 색상을 흰색으로 설정 (필요 시)
    fontSize: '0.9em', // 폰트 크기 조정 (필요 시)
    marginTop: '1em',
  },
  formGroup: {
    width: '100%',
    justifyContent: 'space-between', // 공간을 균등하게 배분
    alignItems: 'center', // 세로 축에서 가운데 정렬
    marginBottom: '2%',
  },
  ttsGroup: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between', // 공간을 균등하게 배분
    alignItems: 'center', // 세로 축에서 가운데 정렬
    marginBottom: '2%',
  },
  sharedGroup: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between', // 공간을 균등하게 배분
    alignItems: 'center', // 세로 축에서 가운데 정렬
    marginBottom: '2%',
  },
  label: {
    display: 'block',
    marginBottom: '0.5em',
    fontWeight: 'bold',
    whiteSpace: 'nowrap',
    color: '#ffffff', // 라벨 텍스트 색상을 하얀색으로 변경
  },
  input: {
    width: '100%',
    padding: '1em',
    fontSize: '1em',
    borderRadius: '0.4em',
    border: '1px solid #1c1c1c',
    // marginBottom: '4em',
    color: '#ffffff', // 입력 텍스트 색상을 흰색으로 변경
    backgroundColor: '#333', // 배경색을 어둡게 변경하여 텍스트가 잘 보이도록 함
    fontFamily: 'Arial, sans-serif', // 글꼴 설정
    '@media (max-width: 600px)': {
      fontSize: '0.65em',
      padding: '1.3em',
    },
  },
  inputDescription: {
    width: '100%',
    padding: '1em',
    fontSize: '1em',
    borderRadius: '0.4em',
    border: '1px solid #1c1c1c',
    minHeight: '7em',
    color: '#ffffff',
    backgroundColor: '#333',
    fontFamily: 'Arial, sans-serif',
    resize: 'none', // 사용자가 박스 크기를 조정할 수 없도록 설정
    // 작은 화면(모바일)일 경우
    '@media (max-width: 600px)': {
      minHeight: '6.5em',
      fontSize: '0.8em',
    },

    // 큰 화면일 경우
    '@media (min-width: 601px)': {
      minHeight: '5em',
    },
  },
  textarea: {
    width: '100%',
    padding: '1em',
    fontSize: '1em',
    borderRadius: '0.4em',
    border: '1px solid #1c1c1c',
    minHeight: '7em',
    color: '#ffffff',
    backgroundColor: '#333',
    fontFamily: 'Arial, sans-serif',
    resize: 'none', // 사용자가 박스 크기를 조정할 수 없도록 설정
    // 작은 화면(모바일)일 경우
    '@media (max-width: 600px)': {
      minHeight: '6em',
      fontSize: '0.8em',
    },

    // 큰 화면일 경우
    '@media (min-width: 601px)': {
      minHeight: '15em',
    },
  },
  dropzone: {
    width: '7em',
    height: '7em',
    borderRadius: '50%',
    border: '0.2em solid #101012',
    backgroundColor: '#1c1c1c',
    display: 'flex',
    flexDirection: 'column', // 수정: 아이콘과 텍스트를 수직으로 배치
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
    cursor: 'pointer',
    margin: '0 auto',
    position: 'relative',
    color: '#ffffff', // 텍스트 색상을 하얀색으로 변경
    // 작은 화면(모바일)일 경우
    '@media (max-width: 600px)': {
      width: '7em',
      height: '7em',
    },
    // 큰 화면일 경우
    '@media (min-width: 601px)': {
      width: '10em',
      height: '10em',
    },
  },
  uploadedImageContainer: {
    marginTop: '1em',
    textAlign: 'center',
  },
  uploadedImage: {
    borderRadius: '100%',
    objectFit: 'cover',
    // 작은 화면(모바일)일 경우
    '@media (max-width: 600px)': {
      width: '7em',
      height: '7em',
    },
    // 큰 화면일 경우
    '@media (min-width: 601px)': {
      width: '10em',
      height: '10em',
    },
  },
  backIcon: {
    width: '1em',
    height: '1em',
    marginRight: '0.5em',
  },
  imgIcon: {
    width: '2em',
    height: '2em',
  },
  selectButton: {
    padding: '1em',
    marginLeft: 'auto',
    borderRadius: '0.4em',
    border: '1px solid #1c1c1c',
    cursor: 'pointer',
    color: '#ffffff', // 버튼 텍스트 색상을 하얀색으로 변경
    backgroundColor: '#333', // 버튼 배경색을 어둡게 변경
  },
  autoGenerateButton: {
    padding: '0.5em',
    borderRadius: '0.4em',
    border: '1px solid #1c1c1c',
    display: 'flex',
    alignItems: 'center', // 아이콘과 텍스트를 수직 가운데 정렬
    cursor: 'pointer',
    marginLeft: '1em',
    marginBottom: '1em',
    color: '#ffffff', // 버튼 텍스트 색상을 하얀색으로 변경
    backgroundColor: '#333', // 버튼 배경색을 어둡게 변경
    '& img': {
      width: '1.2em', // 아이콘 크기를 텍스트 크기에 맞춤
      height: '1.2em',
      marginRight: '0.5em', // 아이콘과 텍스트 간의 간격 조정
    },
  },
  errorText: {
    color: '#f44336',
    marginTop: '0.5em',
  },
  toggleContainer: {
    display: 'flex',
    justifyContent: 'flex-start', // 좌측
    width: '100%',
  },
  toggleButtonWrapper: {
    display: 'flex',
    borderRadius: '0.7em',
    border: '8px solid #1c1c1c',
    overflow: 'hidden',
    marginLeft: 'auto',
    fontSize: '1em',
    width: '45%', // 필요한 경우 너비를 조정하세요
    // 작은 화면(모바일)일 경우
    '@media (max-width: 600px)': {
      width: '80%',
      fontSize: '0.8em',
    },
  },
  toggleButton: {
    flex: 1,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
    padding: '0.5em 0', // 버튼 크기 조정
    transition: 'background-color 0.5s, color 0.5s', // 스무스한 전환을 위한 애니메이션
    '&.active': {
      backgroundColor: '#2d2e2f',
      color: '#fff',
    },
    '&:not(.active)': {
      backgroundColor: '#1c1c1c',
      color: '#656567',
    },
  },
  toggleIcon: {
    marginRight: '0.5em',
  },
  error: {
    color: '#f44336',
    marginTop: '1em',
  },
  loading: {
    color: '#000',
    marginTop: '1em',
  },
  formRowSpaceBetween: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  disabledButton: {
    backgroundColor: '#888',
    cursor: 'not-allowed',
  },
}));
