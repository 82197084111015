import { makeStyles } from '@material-ui/core/styles';
const useStyles = makeStyles(theme => ({
  pageTitle: {
    [theme.breakpoints.down('sm')]: {
      marginTop: '1.5rem',
    },
    paddingLeft: '0.2rem',
    fontSize: '2.5rem',
    color: '#fff',
  },
  separater: {
    borderBottomWidth: '1px',
    borderBottomColor: '#A4A4A4',
    borderBottomStyle: 'solid',
    margin: '1.5rem 0',
  },
  stepHeader: {
    [theme.breakpoints.down('xs')]: {
      fontSize: '1.4rem',
    },
    fontSize: '1.6rem',
    marginBottom: '0.5rem',
    color: '#fff',
    fontWeight: '400',
    display: 'flex',
    alignItems: 'center',
  },
  stepSubHeader: {
    [theme.breakpoints.down('xs')]: {
      fontSize: '0.83rem',
    },
    marginTop: '0.4rem',
    fontSize: '1rem',
    color: '#fff',
    fontWeight: '300',
  },
  closeIcon: {
    position: 'relative',
    width: '1.8rem',
    height: '1.8rem',
    marginRight: '0.3rem',
    marginBottom: '0.6rem',
  },
  inputSourceButtonContainer: {
    marginTop: '1rem',
    backgroundColor: '#818181',
    padding: '0.2rem',
  },
  inputSourceButton: {
    [theme.breakpoints.down('xs')]: {
      padding: '0.2rem 1rem!important',
    },
    fontWeight: '400',
    backgroundColor: '#818181!important',
    color: '#000!important',
    padding: '0.2rem 2rem!important',
    border: 'none!important',
  },
  selected: {
    backgroundColor: '#F0F0F0!important',
    borderRadius: '0.2rem!important',
  },
  dragAndDrop: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '1rem 0',
    justifyContent: 'center',
  },
  dragAndDropText: {
    fontWeight: '300',
    fontSize: '1rem',
    color: '#8A8A8A',
  },
  dragAndDropButton: {
    marginTop: '0.8rem',
    fontWeight: '300',
    fontSize: '1rem',
    color: '#F2F2F2',
    backgroundColor: '#202020',
    borderRadius: '0.4rem',
    padding: '0.5rem 1.5rem',
    color: '#1D1D1D',
    backgroundColor: '#F0F0F0',
  },
  buttonContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  buttonRemainingText: {
    marginLeft: '1rem',
    color: '#fff',
  },
  button: {
    [theme.breakpoints.down('xs')]: {
      padding: '0.7rem 1rem',
      fontSize: '1rem',
    },
    backgroundColor: '#CAFF73',
    padding: '0.7rem 2.5rem',
    fontSize: '1.1rem',
    borderRadius: '0.2rem',
    cursor: 'pointer',
  },
  buttonGrid: {
    display: 'flex',
    justifyContent: 'start',
    marginBottom: '5rem',
  },
  laodingContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  laodingText: {
    marginLeft: '1rem',
    color: '#fff',
    fontWeight: '300',
  },
  recordButtonContainer: {
    width: '24rem',
    maxWidth: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: '1rem',
  },
  youtubeInfoContainer: {
    display: 'flex',
    flexDirection: 'column',
    color: '#fff',
    fontWeight: '400',
  },
  youtubeInfo: {
    display: 'flex',
    alignItems: 'center',
  },
  youtubeInfoDeleteIcon: {
    width: '1.2rem',
    marginLeft: '0.5rem',
    cursor: 'pointer',
  },
  youtubeLinkInput: {
    maxWidth: '20rem',
    width: '100%',
    height: '2.5rem',
    backgroundColor: '#fff',
    borderTopRightRadius: 0,
    borderBottomRightRadius: 0,
    borderTopLeftRadius: 9,
    borderBottomLeftRadius: 9,
  },
  audioPlayerContainer: {
    [theme.breakpoints.down('sm')]: {
      marginLeft: '0.5rem',
    },
    width: '28rem',
    maxWidth: '100%',
    display: 'flex',
    flexDirection: 'column',
    marginLeft: '0.8rem',
    marginTop: '2rem',
    marginBottom: '1rem',
  },
  noMargin: {
    margin: '0',
  },
}));

export { useStyles };
