import React, { useState, useEffect, useRef, useLayoutEffect } from 'react';
import axiosInstance from '../../services/axiosInstance';
import { useLocation, useNavigate } from 'react-router-dom';
import { useStyles } from './ChatPage.styles';
import ChatMessage from './ChatMessage';
import { getTTSInference } from '../../services/page.services';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { appActions } from '../../actions/app.actions.js';
import socket from '../../services/socket';
import DeleteModal from '../layout/DeleteModal';
import SpeechRecognition, { useSpeechRecognition } from 'react-speech-recognition';

const ChatPage = () => {
  const classes = useStyles();
  const location = useLocation();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { i18n } = useTranslation();
  const currentLanguage = i18n.language;
  const navigate = useNavigate();
  const { modelName, image, assistantId, creationPageTtsTrainingId } = location.state;
  const [messages, setMessages] = useState([]);
  const [input, setInput] = useState('');
  const [threadId, setThreadId] = useState(null);
  const [chunkMessage, setChunkMessage] = useState('');
  const [isSendBlocked, setIsSendBlocked] = useState(false);
  const [ttsTrainingId, setTtsTrainingId] = useState(null);
  const chatContainerRef = useRef(null);
  const user = useSelector(state => state.user);
  const userId = user?.id;
  const [lastId, setLastId] = useState(null);
  const [hasMore, setHasMore] = useState(false);
  const [isInitialized, setIsInitialized] = useState(false);
  const prevScrollHeightRef = useRef(0);
  const [isDeleteModalOpen, setDeleteModalOpen] = useState(false);
  //TTS가 재생중인지 아닌지(false면 재생중이 아님)
  const [isPlaying, setIsPlaying] = useState(false);
  //socket에서 end이벤트가 왔는지, 나중에 scroll바를 맨 밑으로 내리기 위함
  const [isEnd, setIsEnd] = useState(true);
  // input박스 입력 안되도록 하기 위함
  const [isInputAvailable, setIsInputAvailable] = useState(true);
  // 녹음 효과 bar 애니메이션 활성화
  const [isRecording, setIsRecording] = useState(false);
  // stt버튼을 눌러서 메세지 중인지(false면 채팅창에 직접 입력한것임)
  const [isVoiceCall, setIsVoiceCall] = useState(false);
  // voice를 보내고 있을때
  const [isVoiceBlocked, setIsVoiceBlocked] = useState(false);
  const [triggerSTTRetry, setTriggerSTTRetry] = useState(false);

  const handleOpenModal = () => {
    setDeleteModalOpen(true);
  };

  const handleCloseModal = () => {
    setDeleteModalOpen(false);
  };

  const handleDelete = async () => {
    try {
      if(threadId === null) {
        console.error('Thread ID is null');
        return;
      }
      const response = await axiosInstance.delete(`/threads/${threadId}`)

      if (response.ok) {
        console.log('Thread deleted successfully');
        setDeleteModalOpen(false);
        window.location.reload();
      } else {
        console.log('Thread deleted successfully');
        window.location.reload();
      }
    } catch (error) {
      console.error('Error deleting thread:', error);
    }
    setDeleteModalOpen(false);
  };

  useEffect(() => {
    const fetchAllThreads = async () => {
      try {
        const response = await axiosInstance.get('/threads');
        const allThreads = response.data.data;
        const userThread = allThreads.find(thread => thread.assistantId === assistantId);

        if (userThread) {
          setThreadId(userThread.id);
          setTtsTrainingId(creationPageTtsTrainingId ?? userThread.assistant.TtsTrainingId);

          await fetchThreadHistory(userThread.id);
        } else {
          await createThread();
        }
      } catch (error) {
        console.error('Error fetching all threads:', error);
        dispatch(appActions.openMessageModal(t('modal.pageLoadFail'), true));
      }
    };

    try {
      if (assistantId && userId) {
        fetchAllThreads();
      }
    } catch (error) {
      console.error('Error in useEffect:', error);
      dispatch(appActions.openMessageModal(t('modal.pageLoadFail'), true));
    }
  }, [assistantId, userId]);

  useEffect(() => {
    try {
      if (threadId && socket.connected && !isInitialized) {
        initializeSocket(threadId);
      }
    } catch (error) {
      console.error('Error initializing socket:', error);
      dispatch(appActions.openMessageModal(t('modal.pageLoadFail'), true));
    }
  }, [threadId, assistantId, userId, isInitialized]);

  useEffect(() => {
    socket.on('connect', () => {
      try {
        if (threadId) {
          console.log('Reconnecting to server...');
          initializeSocket(threadId);
        }
      } catch (error) {
        console.error('Error reconnecting to server:', error);
        dispatch(appActions.openMessageModal(t('modal.pageLoadFail'), true));
      }
    });

    socket.on('chunk', data => {
      try {
        setChunkMessage(prev => prev + data.message.value.replace(/【.*?】/g, ''));
        if (chatContainerRef.current) {
          chatContainerRef.current.scrollTop = chatContainerRef.current.scrollHeight;
        }
      } catch (error) {
        console.error('Error processing chunk message:', error);
        dispatch(appActions.openMessageModal(t('modal.pageLoadFail'), true));
      }
    });

    socket.on('end', data => {
      try {
        setIsEnd(true);
        setMessages(prevMessages => [
          ...prevMessages,
          { role: 'assistant', content: data.message.replace(/【.*?】/g, ''), messageId: data.messageId },
        ]);

        if (isVoiceCall) {
          handlePlayButtonClick({ message: { messageId: data.messageId, content: data.message } });
        } else {
          setIsSendBlocked(false);
          setIsVoiceBlocked(false);
        }
        setChunkMessage('');
        if (chatContainerRef.current) {
          chatContainerRef.current.scrollTop = chatContainerRef.current.scrollHeight;
        }
      } catch (error) {
        console.error('Error processing end message:', error);
        dispatch(appActions.openMessageModal(t('modal.pageLoadFail'), true));
      }
    });

    socket.on('disconnect', () => {
      try {
        console.log('Disconnected from server');
      } catch (error) {
        console.error('Error handling disconnect:', error);
        dispatch(appActions.openMessageModal(t('modal.pageLoadFail'), true));
      }
    });

    return () => {
      socket.off('connect');
      socket.off('chunk');
      socket.off('end');
    };
  }, [chunkMessage]);

  useEffect(() => {
    // voiceCall 모드에서, recording이 끝난 경우 send message
    if (!isRecording && input && isVoiceCall) {
      handleSendMessage();
    }
  }, [input, isRecording, isVoiceCall]);

  useLayoutEffect(() => {
    const handleScroll = async () => {
      try {
        if (chatContainerRef.current.scrollTop === 0 && hasMore) {
          prevScrollHeightRef.current = chatContainerRef.current.scrollHeight;
          await fetchThreadHistory(threadId, lastId);
        }
      } catch (error) {
        console.error('Error handling scroll:', error);
        dispatch(appActions.openMessageModal(t('modal.pageLoadFail'), true));
      }
    };

    const chatContainer = chatContainerRef.current;
    if (chatContainer) {
      chatContainer.addEventListener('scroll', handleScroll);
      return () => {
        chatContainer.removeEventListener('scroll', handleScroll);
      };
    }
  }, [lastId, hasMore, threadId]);

  useLayoutEffect(() => {
    if (chatContainerRef.current && prevScrollHeightRef.current && !isEnd) {
      const currentScrollHeight = chatContainerRef.current.scrollHeight;
      chatContainerRef.current.scrollTop = currentScrollHeight - prevScrollHeightRef.current;
    }
    if (isEnd) {
      chatContainerRef.current.scrollTop = chatContainerRef.current.scrollHeight;
      setIsEnd(false);
    }
  }, [messages]);

  // 대화를 처음 시작하는 경우 thread 생성
  const createThread = async () => {
    try {
      setIsSendBlocked(true);
      console.log('Creating thread...');
      const response = await axiosInstance.post('/threads', { assistantId, socketId: socket.id });
      const newThreadId = response.data.data.id;
      await setThreadId(newThreadId);
      const userThread = response.data.data;
      setTtsTrainingId(creationPageTtsTrainingId);

      if (!isInitialized) {
        initializeSocket(newThreadId);
      }
      initializeChat(newThreadId);

      if (userThread) {
        setTtsTrainingId(
          userThread.TtsTrainingId !== null ? userThread.TtsTrainingId : userThread.assistant.TtsTrainingId
        );
      }
    } catch (error) {
      console.error('Error creating thread:', error);
      dispatch(appActions.openMessageModal(t('modal.pageLoadFail'), true));
    }
  };

  // 이전 대화를 불러옴
  const fetchThreadHistory = async (threadId, lastId) => {
    try {
      console.log('Fetching thread history...');
      const response = await axiosInstance.get(`/threads/${threadId}?lastId=${lastId || ''}`);
      const responseData = response.data.data.body;
      const newMessages = response.data.data.data.map(message => ({
        messageId: message.id,
        role: message.role,
        content: message.content.map(item => item.text.value.replace(/【.*?】/g, '')).join(' '),
      }));

      setMessages(prevMessages => [...newMessages, ...prevMessages]);
      await setLastId(responseData.last_id);
      setHasMore(responseData.has_more);
      console.log('hasMore:', responseData.has_more);

      if (chatContainerRef.current && !lastId) {
        setTimeout(() => {
          chatContainerRef.current.scrollTop = chatContainerRef.current.scrollHeight;
          console.log('scrollTop:', chatContainerRef.current.scrollTop);
        }, 0);
      }
      return chatContainerRef.current.scrollHeight;
    } catch (error) {
      console.error('Error fetching thread history:', error);
      dispatch(appActions.openMessageModal(t('modal.pageLoadFail'), true));
    }
  };

  // socket 연결
  const initializeSocket = threadId => {
    try {
      if (threadId) {
        console.log('Initializing socket...', threadId);
        socket.emit('socketInit', { threadId, assistantId, userId });
        setIsInitialized(true);
      }
    } catch (error) {
      console.error('Error initializing socket:', error);
      dispatch(appActions.openMessageModal(t('modal.pageLoadFail'), true));
    }
  };

  // chat 시작 (대화 처음 시작의 경우, 모델이 먼저 말 걸게끔)
  const initializeChat = threadId => {
    try {
      socket.emit('chatInit', { threadId, assistantId, userId });
    } catch (error) {
      console.error('Error initializing chat:', error);
      dispatch(appActions.openMessageModal(t('modal.pageLoadFail'), true));
    }
  };

  const handleSendMessage = async () => {
    if (input.trim() === '') return;
    if (!threadId) return;
    if (isPlaying) return;
    if (isRecording) return;

    if (!isVoiceCall) {
      if (isSendBlocked) return;
      setIsInputAvailable(true);
    }
    setIsSendBlocked(true);
    setIsVoiceBlocked(true);

    const newMessage = { role: 'user', content: input };
    setMessages(prevMessages => [...prevMessages, newMessage]);
    setInput('');
    if (chatContainerRef.current) {
      chatContainerRef.current.scrollTop = chatContainerRef.current.scrollHeight;
    }

    try {
      socket.emit('chat', { message: input, assistantId, threadId, userId });
      setTimeout(() => {
        if (chatContainerRef.current) {
          chatContainerRef.current.scrollTop = chatContainerRef.current.scrollHeight;
        }
      }, 0);
    } catch (error) {
      console.error('Error sending message:', error);
      dispatch(appActions.openMessageModal(t('modal.pageLoadFail'), true));
      setIsSendBlocked(false);
    }
  };

  // TTS 기능 함수.
  const handlePlayButtonClick = async message => {
    // 이미 TTS가 playing 중이라면 return
    if (isRecording) return;
    if (isPlaying) return;
    if (isSendBlocked && !isVoiceCall) return;
    // if (isSendBlocked) return;
    setIsPlaying(true);
    setIsSendBlocked(true);
    setIsVoiceBlocked(true);

    try {
      if (message.message.messageId) {
        const audioUrl = await convertTextToSpeech(message.message.messageId, message.message.content, ttsTrainingId);
        if (audioUrl) {
          playAudio(audioUrl);
        } else {
          setIsPlaying(false);
        }
      } else {
        setIsPlaying(false);
      }
    } catch (error) {
      console.error('Error handling play button click:', error);
      dispatch(appActions.openMessageModal(t('modal.pageLoadFail'), true));
      setIsPlaying(false);
    }
  };

  const convertTextToSpeech = async (messageId, text, modelId, variability = 50, similarity = 75) => {
    try {
      // 추후 페르소나 요금제에 맞게 variability, similarity, pro 변경 필요
      const inferenceBlob = await getTTSInference(messageId, text, modelId, variability, similarity, 'PRO');
      return URL.createObjectURL(inferenceBlob.slice(0, inferenceBlob.size, `audio/mpeg`));
    } catch (error) {
      console.error('Error converting text to speech:', error);
      dispatch(appActions.openMessageModal(t('modal.pageLoadFail'), true));
      return null;
    }
  };

  useEffect(() => {
    if (triggerSTTRetry) {
      handleSTTButtonClick();
      setTriggerSTTRetry(false);
      toggleListening();
    }
  }, [triggerSTTRetry]);

  const playAudio = audioUrl => {
    try {
      const audio = new Audio(audioUrl);
      audio.play();
      audio.addEventListener('ended', () => {
        setIsPlaying(false);
        setIsVoiceBlocked(false);
        if (!isVoiceCall) {
          setIsSendBlocked(false);
        } else {
          setTriggerSTTRetry(true);
        }
      });
      return audio;
    } catch (error) {
      console.error('Error playing audio:', error);
      dispatch(appActions.openMessageModal(t('modal.pageLoadFail'), true));
      setIsPlaying(false);
    }
  };


  const useSpeechToText = () => {
    const { transcript, listening, resetTranscript } = useSpeechRecognition();
    const toggleListening = () => {
      if (listening) {
        SpeechRecognition.stopListening();
      } else {
        let language = ''
        switch (currentLanguage) {
          case 'en':
              language = 'en-US';
            break;
          case 'ko':
              language = 'ko-KR';
            break;
          case 'th':
              language = 'th-TH';
            break;
          case 'ja':
              language = 'ja-JP';
            break;
          default:
              language = 'ko-KR';
        }
        SpeechRecognition.startListening({ language: language, continuous: false });
        resetTranscript();
      }
    }
    return { transcript, listening, toggleListening };
  };
  const { transcript, toggleListening, listening } = useSpeechToText();

  useEffect(() => {
    if (isRecording && !listening){
      setInput(transcript);
      setIsRecording(false);
      setIsSendBlocked(false);
    }
  }, [listening]);

  // STT 기능 함수. 보이스콜 모드
  const handleSTTButtonClick = () => {
    if (isVoiceBlocked) return;
    if (isPlaying) return;
    if (isRecording) {
      // 보이스톡 종료
      setIsRecording(false);
      setIsVoiceCall(false);
      setIsInputAvailable(true);
      setIsSendBlocked(false);
      toggleListening();
    } else {
      setIsVoiceCall(true);
      setIsRecording(true);
      setIsSendBlocked(true);
      setIsInputAvailable(false);
      setInput('');
      toggleListening();
    }
  };


  const handleKeyPress = e => {
    if (e.key === 'Enter') {
      handleSendMessage();
    }
  };

  return (
    <div className={classes.main}>
      <div className={classes.container}>
        <div className={classes.headerContainer}>
          <div className={classes.headerRow}>
            <button className={classes.backButton} onClick={() => navigate(-1)}>
              뒤로
            </button>
            <div className={classes.headerButtons}>
              <button
                className={classes.deleteButton}
                onClick={handleOpenModal}
              >
                채팅 삭제
              </button>
              <DeleteModal open={isDeleteModalOpen} onClose={handleCloseModal} onDelete={handleDelete} />
            </div>
          </div>
        </div>
        <div className={classes.profileContainer}>
          {image && <img src={image} alt={modelName} className={classes.image} />}
          <h3 className={classes.header}>{modelName}</h3>
        </div>
        <div className={classes.chatContainer} ref={chatContainerRef}>
          {messages.map((message, index) => (
            <ChatMessage
              key={index}
              message={message}
              image={image}
              modelName={modelName}
              handlePlayButtonClick={() => handlePlayButtonClick({ message })}
              isPlaying={isPlaying}
              messageId={message.messageId}
              isSendAvailable={isSendBlocked}
            />
          ))}
          {chunkMessage && (
            <ChatMessage
              key="chunk"
              message={{ role: 'assistant', content: chunkMessage }}
              image={image}
              modelName={modelName}
              handlePlayButtonClick={handlePlayButtonClick}
              isPlaying={isPlaying}
            />
          )}
        </div>
        <div className={classes.inputContainer}>
          <div className={classes.sendContainer}>
            <input
              type="text"
              className={classes.input}
              value={isRecording? transcript:input}
              onChange={e => setInput(e.target.value)}
              onKeyPress={handleKeyPress}
              readOnly={!isInputAvailable}
            />
            <button
              className={`${classes.sendButton} ${isSendBlocked ? 'disabled' : ''} ${isPlaying ? 'disabled' : ''}`}
              onClick={handleSendMessage}
              disabled={isSendBlocked}
            >
              <img src="/sendButton.svg" alt="Send" className={classes.sendIcon} />
            </button>
            {isRecording && (
              <div className={classes.bars}>
                <div className={classes.bar}></div>
                <div className={classes.bar}></div>
                <div className={classes.bar}></div>
              </div>
            )}
          </div>
          <button
            className={`${classes.sttButton} ${isRecording ? 'recording' : ''} ${isPlaying ? 'disabled' : ''} ${isVoiceBlocked ? 'disabled' : ''}`}
            onClick={handleSTTButtonClick}
          >
            <img src="/phoneCall.svg" alt="STT" className={classes.voiceIcon} />
          </button>
        </div>
      </div>
    </div>
  );
};

export default ChatPage;
