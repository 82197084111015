import AppBarAndDrawer from './AppBarAndDrawer/AppBarAndDrawer';

import { useStyles } from './Layout.modules';
import LoginModal from './LoginModal';
import MessageModal from './MessageModal';
import LanguageModal from './LanguageModal';
// import AnnouncementModal from "./AnnouncementModal";
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

function Layout(props) {
  const appProps = useSelector(state => state.app);
  const classes = useStyles();
  const { i18n } = useTranslation();
  const defaultFont = i18n.language === 'ja' ? 'MPlus1' : 'TheJamsil';
  return (
    <div className={defaultFont}>
      {/* <AnnouncementModal open={appProps.openLanguageModal} /> */}
      <LanguageModal open={appProps.openLanguageModal} />
      <LoginModal open={appProps.openLoginModal} />
      <MessageModal
        open={appProps.openMessageModal}
        message={appProps.messageModalText}
        reloadOnClose={appProps.reloadOnClose}
        showRefreshButton={appProps.showRefreshButton}
      />
      <div className="layout">
        <AppBarAndDrawer />
        <main className={classes.main}>{props.children}</main>
      </div>
    </div>
  );
}

export default Layout;
