import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(theme => ({
  userMessageContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginBottom: '10px',
    '@media (max-width: 600px)': {
      maxWidth: '100%',
    },
  },
  assistantMessageContainer: {
    display: 'flex',
    justifyContent: 'flex-start',
    marginBottom: '20px',
    '@media (max-width: 600px)': {
      maxWidth: '100%',
      marginBottom: '30px',
    },
  },
  userMessage: {
    maxWidth: '85%',
    padding: '10px',
    borderRadius: '10px',
    // backgroundColor: '#007bff',
    backgroundColor: '#2d2e2f',
    color: '#c6c8cc',
    lineHeight: '1.2',
    '@media (max-width: 600px)': {
      marginRight: '10px',
      maxWidth: '77%',
      padding: '10px',
      fontSize: '0.9rem',
    },
  },
  assistantMessageWrapper: {
    position: 'relative',
    maxWidth: '85%',
    '@media (max-width: 600px)': {
      maxWidth: '77%',
    },
  },
  assistantMessage: {
    maxWidth: '100%',
    padding: '10px',
    borderRadius: '10px',
    backgroundColor: '#2d2e2f',
    color: '#c6c8cc',
    lineHeight: '1.2',
    '@media (max-width: 600px)': {
      fontSize: '0.9rem',
    },
  },
  avatar: {
    width: '40px',
    height: '40px',
    borderRadius: '50%',
    marginRight: '10px',
    '@media (max-width: 600px)': {
      width: '30px',
      height: '30px',
    },
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    height: '30px',
    marginBottom: '10px',
  },
  modelName: {
    fontWeight: 'bold',
    color: '#FFFFFF',
    marginRight: '10px',
    '@media (max-width: 600px)': {
      fontSize: '0.9rem',
    },
  },
  playButton: {
    background: 'none',
    border: 'none',
    cursor: 'pointer',
    padding: 0,
    display: 'flex',
    alignItems: 'center',
  },
  playIcon: {
    fontSize: '1.0rem',
    color: '#2d2e2f',
  },
  bars: {
    display: 'flex',
    alignItems: 'flex-end',
    justifyContent: 'center',
    width: '24px',
    height: '24px',
    marginBottom: '10px',
  },
  bar: {
    width: '3px',
    height: '50%',
    margin: '0 1px',
    transformOrigin: 'bottom',
    backgroundColor: '#00FF00',
    animation: '$bar 1s infinite ease-in-out',
    '&:nth-child(2)': {
      animationDelay: '0.1s',
    },
    '&:nth-child(3)': {
      animationDelay: '0.2s',
    },
  },
  '@keyframes bar': {
    '0%, 100%': {
      transform: 'scaleY(0.1)',
    },
    '50%': {
      transform: 'scaleY(1)',
    },
  },
}));
