import { makeStyles } from '@material-ui/core/styles';
import { max } from 'moment';

const useStyles = makeStyles(theme => ({
  root: {
    '& .MuiInputLabel-outlined:not(.MuiInputLabel-shrink)': {
      transform: 'translate(34px, 20px) scale(1);',
    },
    '&.Mui-focused .MuiInputLabel-outlined': {
      color: 'purple',
    },
  },
  inputRoot: {
    color: 'purple',
    '&[class*="MuiOutlinedInput-root"] .MuiAutocomplete-input:first-child': {
      paddingLeft: 26,
    },
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: 'green',
    },
    '&:hover .MuiOutlinedInput-notchedOutline': {
      borderColor: 'red',
    },
    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
      borderColor: 'purple',
    },
  },
  pageTitle: {
    [theme.breakpoints.down('sm')]: {
      marginTop: '1.5rem',
      fontSize: '1rem',
    },
    paddingLeft: '1rem',
    fontSize: '1.7rem',
    color: '#fff',
    alignItems: 'center',
    display: 'flex',
  },
  cardGrid: {
    columnGap: '5px',
    display: 'inline-flex',
    flexDirection: 'row',
  },
  cardHeader: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: '0.5rem',
  },
  card: {
    display: 'inline-block',
    flex: '0 0 auto',
    border: '0.1rem solid #2d2e2f',
    backgroundColor: '#161616',
    padding: '.7rem',
    paddingBottom: '.5rem',
    borderRadius: '0.5rem',
    color: '#fff',
    fontSize: '0.9rem',
    fontWeight: '400',
    maxWidth: '25rem',
    marginBottom: '0.5rem',
    marginTop: '0.5rem',
    marginLeft: '0.7rem',
    transition: 'transform 0.3s ease-in-out',
    width: '20rem',
    [theme.breakpoints.down('sm')]: {
      maxWidth: '20rem',
      maxHeight: '5.5rem',
      marginRight: '0',
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: '0.75rem',
    },
    [theme.breakpoints.down(476)]: {
      fontSize: '0.75rem',
      padding: '.4rem',
      paddingBottom: '.3rem',
      height: '5.3rem',
      width: '18rem',
      marginLeft: '0',
    },
    '&:hover': {
      cursor: 'pointer',
      transform: 'scale(1.05)',
    },
    
  },
  cardSecondColumn: {
    flex: 'vertical',
  },
  cardLabel: {
    flex: 1,
    marginLeft: '0.3rem',
    marginBottom: '0.3rem',
    display: 'flex',
    alignItems: 'top',
    fontSize: '0.8rem',
    flexWrap: 'wrap',
    height: 'auto',
    overflow: 'hidden',
    overflowWrap: 'anywhere',
    '@media (max-width: 600px)': {
      fontSize: '0.8rem',
    },
    '@media (min-width: 960px)': {
      fontSize: '1rem',
    },
  },

  cardLastMessage: {
    display: '-webkit-box',
    marginLeft: '0.3rem',
    flex: 1,
    fontSize: '0.7rem',
    color: '#d3d3d3',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    WebkitLineClamp: 2,
    WebkitBoxOrient: 'vertical',
    lineHeight: '0.7rem',
    maxHeight: '2.4rem',
    [theme.breakpoints.down(476)]: {
      fontSize: '0.6rem',
      lineHeight: '0.8rem',
      maxHeight: '2rem',
    },
    '@media (max-width: 960px)': {
      fontSize: '0.65rem',
      lineHeight: '1rem',
    },
  },

  cardUpdatedAt: {
    fontSize: '0.7rem',
    color: '#d3d3d3',
    marginLeft: '0.3rem',
    [theme.breakpoints.down(476)]: {
      fontSize: '0.6rem',
    },
  },
  pagination: {
    fontSize: '1.5rem',
    fontWeight: '500',
    color: '#ffffff',
    margin: '1rem 0',
  },
  button: {
    [theme.breakpoints.down('xs')]: {
      fontSize: '1rem',
    },
    [theme.breakpoints.up('m')]: {
      marginLeft: '50rem',
    },
    [theme.breakpoints.down(1134)]: {
      width: '6.3rem',
    },
    backgroundColor: '#CAFF73',
    padding: '0.6rem 0 0 0',
    margin: '.3rem 0 .5rem 0',
    width: '7.0rem',
    fontSize: '1.1rem',
    borderRadius: '0.2rem',
    textAlign: 'center',
    cursor: 'pointer',
    height: '2.5rem',
    verticalTextAlign: 'center',
  },
  coverImg: {
    userSelect: 'none',
    width: '1.5rem',
    height: '1.5rem',
    display: 'inline-block',
    flexShrink: '1',
    transition: 'fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
    fontSize: '1.5rem',
    borderRadius: '50%',
    border: '0.07rem solid #808080',
    marginRight: '0.5rem',
  },
  infiniteScroll: {
    display: 'flex',
    overflowX: 'scroll',
    scrollbarWidth: 'none',  // For Firefox
    '&::-webkit-scrollbar': {
      display: 'none',  // For Chrome, Safari, and Opera
    },
  },
  body: {
    display: 'flex',
    flexDirection: 'row',
    overflowX: 'auto',
    whiteSpace: 'nowrap',
  },
  scrollContainer: {
    overflowX: 'hidden',  // Hide scrollbar but allow scrolling
    paddingBottom: '10px', // Optional: Add some padding to prevent content clipping
  }
}));

export { useStyles };
