import { appConstants } from '../constants/app.constants';

const INITIAL_STATE = {
  openLoginModal: false,
  openMessageModal: false,
  messageModalText: '',
  downloadProps: {},
  initialized: false,
  selectedModel: null,
};

export function appReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case appConstants.OPEN_LOGIN_MODAL:
      return {
        ...state,
        openLoginModal: true,
        preLoginRoute: action.payload.preLoginRoute,
      };
    case appConstants.CLOSE_LOGIN_MODAL:
      return {
        ...state,
        openLoginModal: false,
      };
    case appConstants.OPEN_MESSAGE_MODAL:
      return {
        ...state,
        openMessageModal: true,
        messageModalText: action.payload.message,
        reloadOnClose: action.payload.reloadOnClose,
        showRefreshButton: action.payload.showRefreshButton,
      };
    case appConstants.CLOSE_MESSAGE_MODAL:
      return {
        ...state,
        openMessageModal: false,
      };
    case appConstants.OPEN_LANGUAGE_MODAL:
      return {
        ...state,
        openLanguageModal: true,
      };
    case appConstants.CLOSE_LANGUAGE_MODAL:
      return {
        ...state,
        openLanguageModal: false,
      };

    case appConstants.SELECT_COMMUNITY_ITEM:
      return {
        ...state,
        selectedModel: action.payload.selectedModel,
      };
    case appConstants.CLEAR_SELECTED_COMMUNITY_ITEM:
      return {
        ...state,
        selectedModel: null,
      };
    case appConstants.SET_INITIALIZED:
      return {
        ...state,
        initialized: action.payload.initialized,
      };
    default:
      return state;
  }
}
