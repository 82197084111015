const getOnetimeItems = (t, currency) => [
  {
    name: t('onetimeItems.training.name'),
    price: t(`onetimeItems.training.price.${currency}`),
    type: 'TRAINING',
  },
  {
    name: t('onetimeItems.vocalExtract.name'),
    price: t(`onetimeItems.vocalExtract.price.${currency}`),
    type: 'VOCAL_EXTRACT',
  },
  {
    name: t('onetimeItems.inference.name'),
    price: t(`onetimeItems.inference.price.${currency}`),
    type: 'INFERENCE',
  },
];
export default getOnetimeItems;
