import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(theme => ({
  dialogPaper: {
    backgroundColor: '#1e1e1e',
    color: '#fff',
    padding: theme.spacing(3),
    minWidth: '300px',
    textAlign: 'center',
    borderRadius: '10px',
    boxShadow: '0px 3px 6px rgba(0, 0, 0, 0.16)',
  },
  title: {
    fontSize: '1.5rem',
    fontWeight: 'bold',
    marginBottom: theme.spacing(2),
  },
  contentText: {
    fontSize: '1rem',
    color: '#ccc',
    marginBottom: theme.spacing(2),
  },
  actions: {
    justifyContent: 'center',
  },
  cancelButton: {
    backgroundColor: '#333',
    color: '#fff',
    padding: '0.5rem 1.5rem',
    '&:hover': {
      backgroundColor: '#444',
    },
  },
  deleteButton: {
    backgroundColor: '#fff',
    color: '#000',
    padding: '0.5rem 1.5rem',
    '&:hover': {
      backgroundColor: '#ddd',
    },
  },
}));

