import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { useDropzone } from 'react-dropzone';
import { useTranslation } from 'react-i18next';
import { useStyles } from './GenerateMusicPage.styles';

import { ReactMic } from 'react-mic';
import _ from 'lodash';
import AudioPlayer from 'material-ui-audio-player';
import ReactJkMusicPlayer from 'react-jinke-music-player';
import 'react-jinke-music-player/assets/index.css';
import ReactTimeAgo from 'react-time-ago';

import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import Grid from '@material-ui/core/Grid';
import Slider from '@material-ui/core/Slider';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import DeleteIcon from '@material-ui/icons/Delete';
import CircularProgress from '@material-ui/core/CircularProgress';
import Select from '@material-ui/core/Select';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import Button from '@material-ui/core/Button';
import MenuItem from '@material-ui/core/MenuItem';
import AddIcon from '@mui/icons-material/Add';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';

import { FormControl, FormControlLabel, Radio, RadioGroup, ListSubheader } from '@mui/material';
import DownloadIcon from '@mui/icons-material/FileDownload';
import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline';
import PauseCircleOutlineIcon from '@mui/icons-material/PauseCircleOutline';
import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined';
import PanToolAltOutlinedIcon from '@mui/icons-material/PanToolAltOutlined';
import ThumbUpOutlinedIcon from '@mui/icons-material/ThumbUpOutlined';
import FavoriteBorderRoundedIcon from '@mui/icons-material/FavoriteBorderRounded';
import FavoriteIcon from '@mui/icons-material/Favorite';

import {
  checkTrainingCompletion,
  fetchSubscriptionInfo,
  checkInferenceCompletion,
  checkInferenceCompletionById,
  getInferenceSignedUrl,
  uploadInference,
  getYoutubeInfo,
  fetchTrainings,
  getCommunitySampleSignedUrl,
} from '../../services/page.services';

import { dragAndDropStyle } from './shared.styles';
import { checkValidLoginStatus } from '../../utils/user.utils';
import { appActions } from '../../actions/app.actions.js';
import { userActions } from '../../actions/user.actions.js';
import { getDefaultModel } from '../../constants/model.constants';
import SelectVoiceModal from '../layout/SelectVoiceModal';

import HtmlTooltip from '../HtmlTooltip';
import coverImg from '../../img/cover.png';
import tooltipImg from '../../img/tooltip.png';
import FreeInferenceMessageModal from '../layout/FreeInferenceMessageModal.js';

function GenerateMusicPage() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const user = useSelector(state => state.user);
  const app = useSelector(state => state.app);
  const userId = user?.id;
  const tierToShowOutputFormat = ['PREMIUM', 'PRO', 'ADMIN', 'CUSTOM'];
  const isAdmin = user?.subscription?.type === 'ADMIN';
  const isCustom = user?.subscription?.type === 'CUSTOM';

  const intervalIDRef = React.useRef(null);
  const [currentValue, setCurrentValue] = useState(0);
  const [loadingText, setLoadingText] = useState('');
  const [submitSubText, setSubmitSubText] = useState('');
  const [alignment, setAlignment] = React.useState('youtube');
  const [outputFormat, setOutputFormat] = React.useState('mp3');

  const [selectedVoiceType, setSelectedVoiceType] = useState(app.selectedModel ? 'community' : '');

  const [selectedModel, setSelectedModel] = useState(app.selectedModel);

  const [needMdx, setNeedMdx] = useState(true);
  const [inferenceRemainingCount, setInferenceRemainingcount] = useState();
  const [inferenceInProgress, setInferenceInProgress] = useState(false);
  const [loading, setLoading] = useState(false);
  const [content, setContent] = useState({});
  const [recordData, setRecordData] = useState();
  const [selectVoiceModalProps, setSelectVoiceModalProps] = useState({});
  const [completedAudio, setCompletedAudio] = React.useState();
  const [showOutputFormatTooltip, setShowOutputFormatTooltip] = useState(false);
  const [openFreeInferenceModal, setOpenFreeInferenceModal] = useState(false);
  const [mic, setMic] = useState(false);

  //play
  const [selectedAudio, setSelectedAudio] = React.useState();
  const [playing, setPlaying] = useState(false);
  const [selectedCommunityId, setSelectedCommunityId] = useState('');

  useEffect(() => () => intervalIDRef.current && clearInterval(intervalIDRef.current), []);

  useEffect(() => {
    return () => {
      dispatch(appActions.clearSelectedCommunityItem());
    };
  }, []);

  useEffect(() => {
    if (userId) (async () => initPage())();
  }, [userId]);

  //add event listened to be triggered whenever the played song ends
  useEffect(() => {
    if (selectedAudio) {
      selectedAudio.addEventListener('ended', () => {
        setSelectedCommunityId('');
        setPlaying(false);
      });
    }
    return () => {
      if (selectedAudio) {
        selectedAudio.pause();
        selectedAudio.removeEventListener('ended', () => {
          setSelectedCommunityId('');
          setPlaying(false);
        });
      }
    };
  }, [selectedAudio]);

  //when people click play button
  useEffect(() => {
    if (selectedAudio) {
      playing ? selectedAudio.play() : selectedAudio.pause();
    }
  }, [playing]);

  const defaultModel = getDefaultModel(t);

  const checkInferenceInProgress = async () => {
    let inferences = await checkInferenceCompletion();
    const inProgress = _.some(inferences, { status: 'PENDING' });
    setInferenceInProgress(inProgress);
  };

  const getBlobFromS3Url = async url => {
    const res = await fetch(url);
    return res.blob();
  };

  const initPage = async () => {
    try {
      if (!isAdmin && !isCustom) {
        await checkInferenceInProgress();
      }
      await checkRemainingCount();
      await checkTrainingCompletion();
      const trainings = await fetchTrainings();
      const communityTrainings = await fetchTrainings();
      const availableTraining = _.sortBy(
        _.map(_.filter(trainings, { status: 'COMPLETE' }), v => ({
          ...v,
          category: 'my voices',
        })),
        ['label']
      );
      const availableCommunityTraining = _.sortBy(
        _.map(_.filter(communityTrainings, { status: 'COMPLETE' }), v => ({
          ...v,
          category: 'community',
        })),
        ['label']
      );
      const completeTrainingList = availableTraining.concat(availableCommunityTraining);
    } catch (e) {
      console.log(e);
      dispatch(appActions.openMessageModal(t('modal.pageLoadFail'), true));
    }
  };

  const checkRemainingCount = async () => {
    let subscriptionInfo = await fetchSubscriptionInfo();
    dispatch(userActions.updateSubscription(subscriptionInfo));
    console.log('subscriptionInfo: ', subscriptionInfo);
    setInferenceRemainingcount(subscriptionInfo?.inferenceRemaining || 0);
    return subscriptionInfo;
  };

  const onDropAccepted = async acceptedFiles => {
    if (checkValidLoginStatus(userId, dispatch) && acceptedFiles.length > 0) {
      const file = acceptedFiles[0];
      setContent({ ...content, file, name: file.name });
    }
  };
  const onDropRejected = async input => {
    if (checkValidLoginStatus(userId, dispatch)) {
      const message = (() => {
        switch (input[0].errors[0].code) {
          case 'file-invalid-type':
            return t('inferenceTab.modal.notSupportedFileType');
          case 'too-many-files':
            return t('inferenceTab.modal.tooManyFiles');
          case 'file-too-large':
            return t('inferenceTab.modal.exceedMaxSize');
        }
      })();
      if (message) dispatch(appActions.openMessageModal(message));
    }
  };
  const { getRootProps, getInputProps } = useDropzone({
    accept: {
      'audio/mp3': ['.mp3'],
      'audio/wav': ['.wav'],
      'audio/mpeg-4': ['.m4a'],
      'audio/flac': ['.flac'],
      'audio/ogg': ['.ogg'],
      // "video/mpeg-4": [".mp4"],
      // "video/x-matroska ": [".mkv"],
    },
    maxFiles: 1,
    onDropAccepted,
    onDropRejected,
    disabled: loadingText,
    maxSize: 78643200,
  });

  const checkCompleteForm = () => {
    let message = '';
    if (inferenceInProgress) {
      message = t('inferenceTab.modal.inProgress');
    } else if (inferenceRemainingCount <= 0) {
      message = t('inferenceTab.modal.noRemaining');
    } else if (!content.file && !content.youtubeInfo) {
      message = t('inferenceTab.modal.emptyYoutubeLink');
    } else if (!selectedModel?.id) {
      message = t('inferenceTab.modal.noModalSelected');
    } else if (!content.label) {
      message = t('inferenceTab.modal.noLabel');
    }
    if (message) {
      dispatch(appActions.openMessageModal(message));
      return false;
    }
    return true;
  };

  const checkYoutubeLink = async () => {
    setLoading(true);
    try {
      const { duration, valid, name } = await getYoutubeInfo(content.youtubeLink);
      if (!valid) {
        setContent({ ...content, youtubeLink: '', name: null });
        dispatch(appActions.openMessageModal(t('inferenceTab.modal.invalidYoutubeLink')));
      } else if (duration > 600) {
        setContent({ ...content, youtubeLink: '', name: null });
        dispatch(appActions.openMessageModal(t('inferenceTab.modal.youtubeLinkTooLong')));
      } else {
        setContent({
          ...content,
          youtubeInfo: { duration, name },
          name,
        });
      }
    } catch (e) {
      dispatch(appActions.openMessageModal(t('inferenceTab.submit.tryAgain')));
    } finally {
      setLoading(false);
    }
  };

  const setUploadType = uploadType => {
    setAlignment(uploadType);
    setNeedMdx(uploadType != 'record');
    setContent({
      ...content,
      youtubeLink: '',
      file: null,
      youtubeInfo: null,
      name: null,
    });
  };

  const handleSubmit = async e => {
    if (!checkCompleteForm()) return;
    setCompletedAudio(null);
    setLoadingText(t('inferenceTab.submit.uploading'));
    let inference;
    console.log(`selectedModel = ${selectedModel}`);
    console.log(selectedModel);
    const community = selectedVoiceType === 'community';
    try {
      inference = await uploadInference(
        userId,
        content,
        currentValue,
        needMdx,
        selectedModel,
        user.subscription.type,
        outputFormat,
        community
      );
    } catch (e) {
      dispatch(appActions.openMessageModal(t('inferenceTab.submit.tryAgain')));
      setLoadingText('');
      return;
    }
    setLoadingText(t('inferenceTab.submit.inProgress'));
    if (
      user.subscription.type === 'FREE' &&
      user.subscription.baseInferenceRemaining === user.subscription.inferenceRemaining
    ) {
      setOpenFreeInferenceModal(true);
    }

    clearInterval(intervalIDRef.current);
    const tick = async () => {
      const id = inference.id;
      const combinedFileName = `${id}.${inference.outputFormat}`;
      const voiceFileName = `${id}-voice.${inference.outputFormat}`;
      const instrumentalFileName = `${id}-instrument.${inference.outputFormat}`;
      try {
        const status = await checkInferenceCompletionById(id);
        if (status === 'COMPLETE') {
          clearInterval(intervalIDRef.current);
          const voiceUrl = await getInferenceSignedUrl(id, voiceFileName);
          let combinedUrl;
          let instrumentalUrl;
          if (needMdx) {
            combinedUrl = await getInferenceSignedUrl(id, combinedFileName);
            instrumentalUrl = await getInferenceSignedUrl(id, instrumentalFileName);
          }
          setLoadingText('');
          setSubmitSubText(t('inferenceTab.submit.completedAndLoadingPlayer'));
          const voiceBlob = await getBlobFromS3Url(voiceUrl);
          const combinedBlob = combinedUrl && (await getBlobFromS3Url(combinedUrl));
          const instrumentalBlob = instrumentalUrl && (await getBlobFromS3Url(instrumentalUrl));

          await checkRemainingCount();
          setCompletedAudio(
            _.compact([
              combinedBlob && {
                name: `${content.label}`,
                musicSrc: URL.createObjectURL(
                  combinedBlob.slice(0, combinedBlob.size, `audio/${inference.outputFormat}`)
                ),
                extension: inference.outputFormat,
              },
              {
                name: `${content.label}-voice`,
                musicSrc: URL.createObjectURL(voiceBlob.slice(0, voiceBlob.size, `audio/${inference.outputFormat}`)),
                extension: inference.outputFormat,
              },
              instrumentalBlob && {
                name: `${content.label}-instrument`,
                musicSrc: URL.createObjectURL(
                  instrumentalBlob.slice(0, instrumentalBlob.size, `audio/${inference.outputFormat}`)
                ),
                extension: inference.outputFormat,
              },
            ])
          );
          setSubmitSubText(t(''));
        } else if (status === 'FAILED') {
          clearInterval(intervalIDRef.current);
          setLoadingText('');
        }
      } catch (e) {}
    };
    intervalIDRef.current = setInterval(tick, 5000);
  };

  const onStop = audioData => {
    if (audioData.blob.size < 100) {
      return;
    }
    setRecordData(audioData);
    setContent({ ...content, file: audioData.blob, name: 'USER_RECORDING' });
  };

  const customDownloader = downloadInfo => {
    const downloadingAudio = _.find(completedAudio, {
      musicSrc: downloadInfo.src,
    });
    const link = document.createElement('a');
    link.href = downloadInfo.src;
    link.download = `${downloadingAudio.name}.${downloadingAudio.extension}`;
    document.body.appendChild(link);
    link.click();
  };

  const onBeforeDestroy = () => {
    return new Promise((_resolve, reject) => {
      setCompletedAudio(null);
      reject();
    });
  };

  const handleWavButtonClick = () => {
    if (checkValidLoginStatus(userId, dispatch)) {
      if (!tierToShowOutputFormat.includes(user.subscription?.type)) {
        setShowOutputFormatTooltip(true);
      } else {
        setOutputFormat('wav');
        setShowOutputFormatTooltip(false);
      }
    }
  };

  const handleSelectVoice = async () => {
    setSelectVoiceModalProps({
      open: true,
      onSelectVoice: async (selectedVoice, selectedVoiceType) => {
        //setSelectVoiceModalProps({ open: true, loading: true });
        setSelectedModel(selectedVoice);
        setSelectedVoiceType(selectedVoiceType);
        setSelectVoiceModalProps({ open: false });
      },
      onSelectVoiceModalClose: () => {
        setSelectVoiceModalProps({ open: false });
      },
    });
  };

  const handlePlay = async (communityItemId, label) => {
    if (selectedCommunityId === communityItemId) {
      setPlaying(true);
    } else {
      setPlaying(false);
      const sampleVoiceUrl = await getCommunitySampleSignedUrl(communityItemId);
      const sampleVoiceBlob = await getBlobFromS3Url(sampleVoiceUrl);
      if (sampleVoiceBlob.size > 2000) {
        const audio = new Audio(URL.createObjectURL(sampleVoiceBlob.slice(0, sampleVoiceBlob.size, `audio/mp3`)));

        setSelectedAudio(audio);
        setPlaying(true);
        setSelectedCommunityId(communityItemId);
      }
    }
  };

  const handlePlay2 = _.debounce(handlePlay, 500, { leading: true });

  const handlePause = async (communityItemId, label) => {
    setPlaying(false);
  };

  console.log(' inferenceRemainingCount : ', inferenceRemainingCount);
  const classes = useStyles();
  return (
    <>
      <FreeInferenceMessageModal open={openFreeInferenceModal} setOpenFreeInferenceModal={setOpenFreeInferenceModal} />
      <SelectVoiceModal {...selectVoiceModalProps} />
      <div>
        <div className={classes.pageTitle}>{t('inferenceTab.title')}</div>
        <div className={classes.separater} />
        <Grid container spacing={3}>
          <Grid item xs={12} sm={12} md={12}>
            <div className={classes.stepHeader}>
              {t('inferenceTab.stepOne.title')}
              <HtmlTooltip
                title={
                  <div className={classes.tooltipContainer}>
                    <div className={classes.tooltipHeader}>{t('inferenceTab.stepOne.tooltip.title')}</div>
                    <div className={classes.tooltipTextContainer}>
                      <div className={`${classes.tooltipText} ${classes.tooltipTextNumber}`}>1.</div>
                      <div className={classes.tooltipText}>{t('inferenceTab.stepOne.tooltip.contents.0')}</div>
                    </div>

                    <div className={classes.tooltipTextContainer}>
                      <div className={`${classes.tooltipText} ${classes.tooltipTextNumber}`}>2.</div>
                      <div className={classes.tooltipText}>{t('inferenceTab.stepOne.tooltip.contents.1')}</div>
                    </div>
                    <div className={classes.tooltipTextContainer}>
                      <div className={`${classes.tooltipText} ${classes.tooltipTextNumber}`}>3.</div>
                      <div className={classes.tooltipText}>{t('inferenceTab.stepOne.tooltip.contents.2')}</div>
                    </div>
                    <div className={classes.tooltipTextContainer}>
                      <div className={`${classes.tooltipText} ${classes.tooltipTextNumber}`}>4.</div>
                      <div className={classes.tooltipText}>{t('inferenceTab.stepOne.tooltip.contents.1')}</div>
                    </div>
                  </div>
                }
              >
                <img className={classes.tooltipImg} src={tooltipImg} alt="tooltip-img" />
              </HtmlTooltip>
            </div>
            <div className={classes.stepSubHeader}>{t('inferenceTab.stepOne.subTitles.0')}</div>
            <ButtonGroup className={classes.inputSourceButtonContainer}>
              <Button
                className={`${classes.inputSourceButton} ${alignment == 'youtube' ? classes.selected : ''}`}
                onClick={() => setUploadType('youtube')}
              >
                {t('inferenceTab.stepOne.tabs.youtube.title')}
              </Button>
              <Button
                className={`${classes.inputSourceButton} ${alignment == 'upload' ? classes.selected : ''}`}
                onClick={() => setUploadType('upload')}
              >
                {t('inferenceTab.stepOne.tabs.fileUpload.title')}
              </Button>
              <Button
                className={`${classes.inputSourceButton} ${alignment == 'record' ? classes.selected : ''}`}
                onClick={() => setUploadType('record')}
              >
                {t('inferenceTab.stepOne.tabs.recording.title')}
              </Button>
            </ButtonGroup>
            {alignment === 'record' && (
              <>
                <div className={classes.recordContainer}>
                  {!recordData && (
                    <div>
                      <ReactMic
                        record={mic}
                        className={classes.mic}
                        onStop={onStop}
                        strokeColor="#fff"
                        backgroundColor="#1e1e1e"
                        mimeType="audio/webm"
                        visualSetting="sinewave"
                      />
                      <div className={classes.recordButtonContainer}>
                        {mic ? (
                          <div className={classes.onRecordingButton}>
                            {' '}
                            {t('inferenceTab.stepOne.tabs.recording.recordingButton')}
                          </div>
                        ) : (
                          <button className={classes.recordingButton} onClick={() => setMic(true)} type="button">
                            {t('inferenceTab.stepOne.tabs.recording.startButton')}
                          </button>
                        )}
                        <button className={classes.recordingButton} onClick={() => setMic(false)} type="button">
                          {t('inferenceTab.stepOne.tabs.recording.saveButton')}
                        </button>
                      </div>
                    </div>
                  )}
                </div>

                {recordData && (
                  <div className={classes.audioPlayerContainer}>
                    <AudioPlayer
                      icons={{ CloseIcon: DeleteIcon }}
                      useStyles={useStyles}
                      onClose={() => {
                        setRecordData(null);
                        setContent({ ...content, file: null, name: null });
                      }}
                      volume={false}
                      displayCloseButton
                      src={recordData && recordData.blobURL}
                    />
                  </div>
                )}
              </>
            )}
            {alignment === 'upload' && (
              <>
                {!content.file && (
                  <div className={classes.recordButtonContainer}>
                    <div {...getRootProps({ style: dragAndDropStyle })}>
                      <input {...getInputProps()} />
                      <div className={classes.dragAndDrop}>
                        <div className={classes.dragAndDropText}>
                          {t('inferenceTab.stepOne.tabs.fileUpload.dragAndDropText')}
                          {t('inferenceTab.stepOne.tabs.fileUpload.dragAndDropDuration')}
                        </div>
                        <div className={classes.dragAndDropButton}>
                          {t('inferenceTab.stepOne.tabs.fileUpload.dragAndDropButton')}
                        </div>
                      </div>
                    </div>
                  </div>
                )}

                {content.file && (
                  <div className={classes.imgContainer}>
                    <AudioPlayer
                      icons={{ CloseIcon: DeleteIcon }}
                      useStyles={useStyles}
                      onClose={() => setContent({ ...content, file: null, name: null })}
                      displayCloseButton
                      volume={false}
                      src={URL.createObjectURL(content.file)}
                    />
                  </div>
                )}
              </>
            )}
            {alignment === 'youtube' && (
              <div className={classes.recordButtonContainer}>
                {content.youtubeInfo ? (
                  <div className={classes.youtubeInfoContainer}>
                    <div className={classes.youtubeInfo}>
                      {content.youtubeInfo.name} ({Math.floor(content.youtubeInfo.duration / 60)}
                      {t('inferenceTab.stepOne.tabs.youtube.durationUnit.0')}
                      {content.youtubeInfo.duration % 60}
                      {t('inferenceTab.stepOne.tabs.youtube.durationUnit.1')})
                      <DeleteIcon
                        className={classes.youtubeInfoDeleteIcon}
                        onClick={() =>
                          setContent({
                            ...content,
                            youtubeInfo: null,
                            youtubeLink: '',
                            name: null,
                          })
                        }
                      />
                    </div>
                  </div>
                ) : (
                  <>
                    <OutlinedInput
                      placeholder={t('inferenceTab.stepOne.tabs.youtube.linkPlaceholder')}
                      onChange={e => setContent({ ...content, youtubeLink: e.target.value })}
                      value={content.youtubeLink}
                      className={classes.youtubeLinkInput}
                    />
                    <Button
                      // defining style here because for some reason some styles
                      // doesn't get applied correctly on production
                      style={{
                        backgroundColor: '#242A2D',
                        borderTopLeftRadius: 0,
                        borderBottomLeftRadius: 0,
                        borderTopRightRadius: 9,
                        borderBottomRightRadius: 9,
                        boxShadow: 'none',
                        width: '7.5rem',
                        cursor: 'pointer',
                        color: '#fff',
                      }}
                      disabled={!content.youtubeLink || loading}
                      onClick={checkYoutubeLink}
                    >
                      {loading ? (
                        <CircularProgress
                          // defining style here because for some reason some styles
                          // doesn't get applied correctly on production
                          style={{ width: '1rem', height: '1rem' }}
                        />
                      ) : (
                        <div className={classes.buttonText}>{t('inferenceTab.stepOne.tabs.youtube.linkbutton')}</div>
                      )}
                    </Button>
                  </>
                )}
              </div>
            )}
            {alignment === 'upload' && (
              <Grid className={classes.mdxContainer} item xs={12} sm={12} md={12}>
                <FormControl className={classes.radioButtonContainer}>
                  <div className={classes.radioButtonLabel}>
                    {t('inferenceTab.stepOne.tabs.fileUpload.vocalExtractionText')}
                    <HtmlTooltip
                      title={
                        <div className={classes.tooltipContainer}>
                          <div className={classes.tooltipHeader}>
                            {t('inferenceTab.stepOne.tabs.fileUpload.vocalExtractionTooltip.title')}
                          </div>
                          <div className={classes.tooltipTextContainer}>
                            <div className={`${classes.tooltipText} ${classes.tooltipTextNumber}`}>1.</div>
                            <div className={classes.tooltipText}>
                              {t('inferenceTab.stepOne.tabs.fileUpload.vocalExtractionTooltip.contents.0')}
                            </div>
                          </div>
                          <div className={classes.tooltipTextContainer}>
                            <div className={`${classes.tooltipText} ${classes.tooltipTextNumber}`}>2.</div>
                            <div className={classes.tooltipText}>
                              {t('inferenceTab.stepOne.tabs.fileUpload.vocalExtractionTooltip.contents.1')}
                            </div>
                          </div>
                        </div>
                      }
                    >
                      <img className={classes.tooltipImg} src={tooltipImg} alt="tooltip-img" />
                    </HtmlTooltip>
                  </div>
                  <RadioGroup row>
                    <FormControlLabel
                      checked={!needMdx}
                      style={{ color: '#fff' }}
                      control={<Radio style={{ color: '#fff' }} onClick={() => setNeedMdx(false)} />}
                      label={t('inferenceTab.stepOne.tabs.fileUpload.vocalExtractionYes')}
                    />
                    <FormControlLabel
                      checked={needMdx}
                      style={{ color: '#fff' }}
                      control={<Radio style={{ color: '#fff' }} onClick={() => setNeedMdx(true)} />}
                      label={t('inferenceTab.stepOne.tabs.fileUpload.vocalExtractionNo')}
                    />
                  </RadioGroup>
                </FormControl>
              </Grid>
            )}
          </Grid>
          <Grid item xs={12} sm={12} md={12}>
            <div className={`${classes.separater} ${classes.noMargin}`} />
          </Grid>
          <Grid item xs={12} sm={12} md={12}>
            <div className={classes.stepHeader}>{t('inferenceTab.stepTwo.title')}</div>
            <div className={classes.stepSubHeader}>{t('inferenceTab.stepTwo.subTitles.0')}</div>
          </Grid>
          <Grid style={{ marginBottom: '0.5rem' }} item xs={12} sm={12} md={6}>
            {selectedModel && (
              <Grid className={classes.card} item container key={selectedModel.id}>
                <Grid item xs={3} sm={3} md={3}>
                  <img
                    className={classes.coverImg}
                    src={selectedModel.image ? selectedModel.image : coverImg}
                    alt="cover-img"
                  />
                </Grid>
                <Grid
                  container
                  className={classes.cardSecondColumn}
                  //style={{ border: "1px solid #fafafa" }}
                  item
                  xs={9}
                  sm={9}
                  md={9}
                >
                  <Grid container item>
                    <Grid container item xs={10} sm={10} md={10}>
                      <div className={classes.cardLabel}>{selectedModel.label}</div>
                    </Grid>
                    <Grid
                      container
                      item
                      className={classes.playButtonGrid}
                      xs={2}
                      sm={2}
                      md={2}
                      //style={{ border: "1px solid #fafafa" }}
                    >
                      {playing && selectedModel.id === selectedCommunityId ? (
                        <PauseCircleOutlineIcon
                          className={classes.playButtonImg}
                          onClick={event => {
                            event.stopPropagation();
                            handlePause(selectedModel.id, selectedModel.label);
                          }}
                        />
                      ) : (
                        <PlayCircleOutlineIcon
                          className={classes.playButtonImg}
                          onClick={event => {
                            event.stopPropagation();
                            handlePlay2(selectedModel.id, selectedModel.label);
                          }}
                        />
                      )}
                      <HighlightOffIcon
                        onClick={() => {
                          setSelectedCommunityId('');
                          setPlaying(false);
                          setSelectedModel(null);
                        }}
                        className={classes.cancelButtonImg}
                      />
                    </Grid>
                  </Grid>
                  <Grid container item>
                    <div className={classes.cardUsername}>{selectedModel.username ? selectedModel.username : ''}</div>
                  </Grid>
                  <Grid
                    container
                    item
                    style={{
                      maxHeight: '2rem',
                    }}
                  >
                    <Grid
                      container
                      item
                      xs={9}
                      sm={9}
                      md={9}
                      style={{
                        alignItems: 'flex-end',
                        maxHeight: '1.1rem',
                      }}
                    >
                      <div className={classes.cardTimestamp}>
                        <ReactTimeAgo date={selectedModel.createdAt} locale="en-US" />
                      </div>
                    </Grid>
                    <Grid
                      container
                      item
                      xs={3}
                      sm={3}
                      md={3}
                      className={classes.cardLikes}
                      style={{
                        maxHeight: '2rem',
                        minHeight: '1.5rem',
                      }}
                    >
                      {!selectedModel.liked && (
                        <FavoriteBorderRoundedIcon className={classes.favoriteImg} fontSize="inherit" />
                      )}
                      {selectedModel.liked && <FavoriteIcon className={classes.favoriteFullImg} fontSize="inherit" />}
                      <div className={classes.numberLikes}>
                        {selectedModel.likedNumber ? selectedModel.likedNumber : ''}
                      </div>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            )}
            {!selectedModel && (
              <div className={classes.selectModelOuterContainer} onClick={() => handleSelectVoice()}>
                <div className={classes.selectModelButton}>
                  <div className={classes.selectModelContainer}>
                    <div className={classes.dragAndDropText}>{t('inferenceTab.stepTwo.dragAndDropText')}</div>
                    <AddIcon className={classes.addButtonImg} />
                  </div>
                </div>
              </div>
            )}
          </Grid>

          <Grid
            style={{
              marginBottom: '0.5rem',
            }}
            item
            xs={12}
            sm={12}
            md={6}
          >
            <div className={classes.sliderContainer}>
              <div className={classes.sliderText}>
                {t('inferenceTab.stepTwo.keyInput.title')}
                <HtmlTooltip
                  title={
                    <div className={classes.tooltipContainer}>
                      <div className={classes.tooltipTextContainer}>
                        <div className={`${classes.tooltipText} ${classes.tooltipTextBullet}`}>&#x2022;</div>
                        <div className={classes.tooltipText}>
                          {t('inferenceTab.stepTwo.keyInput.tooltip.contents.0')}
                        </div>
                      </div>
                      <div className={classes.tooltipTextContainer}>
                        <div className={`${classes.tooltipText} ${classes.tooltipTextBullet}`}>&#x2022;</div>
                        <div className={classes.tooltipText}>
                          {t('inferenceTab.stepTwo.keyInput.tooltip.contents.1')}
                        </div>
                      </div>
                      <div className={classes.tooltipTextContainer}>
                        <div className={`${classes.tooltipText} ${classes.tooltipTextBullet}`}>&#x2022;</div>
                        <div className={classes.tooltipText}>
                          {t('inferenceTab.stepTwo.keyInput.tooltip.contents.2')}
                        </div>
                      </div>
                    </div>
                  }
                >
                  <img className={classes.tooltipImg} src={tooltipImg} alt="tooltip-img" />
                </HtmlTooltip>
              </div>
              <Slider
                defaultValue={0}
                value={currentValue}
                valueLabelDisplay="on"
                step={1}
                marks
                min={-12}
                max={12}
                onChange={(e, val) => {
                  if (checkValidLoginStatus(userId, dispatch)) {
                    setCurrentValue(val);
                  }
                }}
              />
            </div>
          </Grid>
          <Grid item xs={12} sm={12} md={12}>
            <div className={`${classes.separater} ${classes.noMargin}`} />
          </Grid>
          <Grid item xs={12} sm={12} md={12}>
            <div className={classes.stepHeader}>{t('inferenceTab.stepThree.title')}</div>
          </Grid>
          <Grid style={{ marginBottom: '0.5rem' }} item xs={12} sm={12} md={6}>
            <OutlinedInput
              onChange={e => {
                if (checkValidLoginStatus(userId, dispatch)) {
                  setContent({ ...content, label: e.target.value });
                }
              }}
              placeholder={t('inferenceTab.stepThree.labelInput.placeHolder')}
              style={{
                width: '100%',
                backgroundColor: '#fff',
              }}
              disabled={loading}
            />
          </Grid>
          <Grid style={{ marginBottom: '0.5rem' }} item xs={12} sm={12} md={12}>
            <div className={classes.stepSubHeader}>{t('inferenceTab.stepThree.ouputFormatLabel')}</div>
            <ButtonGroup className={classes.inputSourceButtonContainer}>
              <Button
                className={`${classes.inputSourceButton} ${outputFormat == 'mp3' ? classes.selected : ''}`}
                onClick={() => setOutputFormat('mp3')}
              >
                {'MP3'}
              </Button>
              <HtmlTooltip
                title={
                  <div className={classes.tooltipContainer}>
                    <div className={classes.tooltipTextContainer}>
                      <div className={classes.tooltipText}>
                        {t('inferenceTab.stepThree.tooltip.outputFormatMessage')}
                      </div>
                    </div>
                    <div className={classes.tooltipTextContainer}>
                      <Link to="/subscription" className={classes.tooltipTextLink}>
                        {user.subscription?.type === 'BASIC'
                          ? t('inferenceTab.stepThree.tooltip.upgradePrompt')
                          : t('inferenceTab.stepThree.tooltip.subscribePrompt')}
                      </Link>
                    </div>
                  </div>
                }
                interactive
                leaveDelay={200}
                open={showOutputFormatTooltip && !tierToShowOutputFormat.includes(user.subscription?.type)}
                onClose={() => setShowOutputFormatTooltip(false)}
              >
                <Button
                  className={`${classes.inputSourceButton} ${outputFormat === 'wav' ? classes.selected : ''}`}
                  onClick={handleWavButtonClick}
                >
                  {'WAV'}
                </Button>
              </HtmlTooltip>
            </ButtonGroup>
          </Grid>
          {(!loadingText || isAdmin || isCustom) && (
            <Grid
              className={`${classes.buttonGrid} ${isAdmin && loadingText && classes.adminButtonGrid}`}
              item
              xs={12}
              sm={12}
              md={12}
            >
              <div>
                <div className={classes.buttonContainer}>
                  <div className={classes.button} onClick={handleSubmit}>
                    {inferenceInProgress
                      ? t('inferenceTab.submit.alreadyInProgress')
                      : userId && inferenceRemainingCount <= 0
                        ? t('inferenceTab.submit.noRemaining')
                        : t('inferenceTab.submit.start')}
                  </div>
                  <HtmlTooltip
                    title={
                      <div className={classes.tooltipContainer}>
                        <div className={classes.tooltipTextContainer}>
                          <div className={`${classes.tooltipText} ${classes.tooltipTextBullet}`}>&#x2022;</div>
                          <div className={classes.tooltipText}>{t('inferenceTab.submit.tooltip.contents.0')}</div>
                        </div>
                        <div className={classes.tooltipTextContainer}>
                          <div className={`${classes.tooltipText} ${classes.tooltipTextBullet}`}>&#x2022;</div>
                          <div className={classes.tooltipText}>{t('inferenceTab.submit.tooltip.contents.1')}</div>
                        </div>
                        <div className={classes.tooltipTextContainer}>
                          <div className={`${classes.tooltipText} ${classes.tooltipTextBullet}`}>&#x2022;</div>
                          <div className={classes.tooltipText}>{t('inferenceTab.submit.tooltip.contents.2')}</div>
                        </div>
                      </div>
                    }
                  >
                    <img className={classes.tooltipImg} src={tooltipImg} alt="tooltip-img" />
                  </HtmlTooltip>
                  {userId &&
                    (user.subscription?.status === 'past_due' || user.subscription?.type !== 'PREMIUM') &&
                    inferenceRemainingCount > 0 && (
                      <div className={classes.buttonRemainingText}>
                        {t('inferenceTab.submit.remainingCountText')}: {inferenceRemainingCount}
                        {t('inferenceTab.submit.remainingCountUnit')}
                      </div>
                    )}
                </div>
                {submitSubText && <div className={classes.buttonSubText}>{submitSubText}</div>}
              </div>
            </Grid>
          )}
          {loadingText && (
            <Grid className={classes.buttonGrid} item xs={12} sm={12} md={12}>
              <div className={classes.laodingContainer}>
                <CircularProgress size="1.4rem" />
                <span className={classes.laodingText}>{loadingText}</span>
              </div>
            </Grid>
          )}
          {completedAudio && (
            <ReactJkMusicPlayer
              mode="full"
              theme="light"
              autoPlay={false}
              showPlayMode={false}
              showThemeSwitch={false}
              showMiniModeCover={false}
              autoHiddenCover
              onBeforeDestroy={onBeforeDestroy}
              spaceBar={true}
              showDestroy={true}
              responsive={false}
              showReload={false}
              toggleMode={false}
              remove={false}
              customDownloader={customDownloader}
              audioLists={completedAudio}
            />
          )}
        </Grid>
      </div>
    </>
  );
}

export default GenerateMusicPage;
