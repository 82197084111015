import { makeStyles } from '@material-ui/core/styles';
import zIndex from '@material-ui/core/styles/zIndex';
import { ForkRight, RiceBowl } from '@mui/icons-material';

export const useStyles = makeStyles(theme => ({
  main: {
    // top: '-65px',
    '@media (max-width: 600px)': {
      marginLeft: '-2.5rem',  // 좌측 마진을 음수로 설정하여 패딩 상쇄
      // paddingLeft: '1.2rem', // 좌측 패딩을 추가하여 마진 상쇄
      marginRight: '-2.5rem', // 우측 마진을 음수로 설정하여 패딩 상쇄
      // paddingRight: '1.2rem', // 우측 패딩을 추가하여 마진 상쇄
    },
    '@media (min-width: 960px)': {
      marginTop: '-65px',
    },
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    height: '90vh',
    backgroundColor: '#101012',
    color: '#fff',
    '@media (min-width: 960px)': {
      height: '100vh',
    },
    //overflow: 'hidden', // Prevent scrolling
  },
  // main: {
  //   position: 'absolute',
  //   [theme.breakpoints.up('md')]: {
  //     marginLeft: '240px',
  //     width: 'calc(100% - 240px)',
  //   },
  //   top: '65px',
  //   backgroundColor: '#fff',
  //   width: '100%',
  //   padding: '0 2.5rem',
  //   marginLeft: '0px',
  // },
  // layout: {},
  headerContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    marginTop: '0.625rem', // 10px
    width: '100%',
    height: '0',
    zIndex: '1',
    boxSizing: 'border-box', // Ensure padding is included in width
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      alignItems: 'flex-start',
    },
  },
  headerRow: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
    boxSizing: 'border-box',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'row',
      alignItems: 'flex-start',
    },
  },
  headerButtons: {
    width: 'auto',
    display: 'flex',
    whiteSpace: 'nowrap',
    gap: '1.25rem', // 20px
    marginLeft: 'auto', // Pushes this element to the right
    justifyContent: 'flex-end',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  backButton: {
    color: '#fff',
    backgroundColor: '#101012',
    border: 'none',
    borderRadius: '0.5rem', // 8px
    padding: '0.625rem 1.25rem', // 10px 20px
    cursor: 'pointer',
    fontFamily: 'Pretendard',
    fontWeight: '700',
    fontSize: 'auto', // 18px
    lineHeight: '1.5rem', // 24px
    textAlign: 'center',
    whiteSpace: 'nowrap',
    '@media (max-width: 600px)': {
      padding: '0.5rem 1rem', // 10px 20px
      fontSize: '0.75rem', // 12px
      lineHeight: '1rem', // 16px
    },
  },
  deleteButton: {
    color: '#fff',
    backgroundColor: '#2d2e2f',
    border: 'none',
    borderRadius: '0.5rem', // 8px
    padding: '0.375rem 1rem', // 6px 16px
    cursor: 'pointer',
    fontFamily: 'Pretendard',
    fontWeight: '700',
    fontSize: '0.875rem', // 14px
    lineHeight: '1.25rem', // 20px
    '@media (max-width: 600px)': {
      padding: '0.3rem 0.8rem', // 6px 12px
      fontSize: '0.75rem', // 12px
      lineHeight: '1rem', // 16px
      marginRight: '0.5rem', // 8px
    },
  },
  newChatButton: {
    color: '#1c1c1c',
    backgroundColor: '#ffffff',
    border: 'none',
    borderRadius: '0.5rem', // 8px
    padding: '0.75rem 1.25rem 0.75rem 0.875rem', // 12px 20px 12px 14px
    cursor: 'pointer',
    fontFamily: 'Pretendard',
    fontWeight: '700',
    fontSize: '0.875rem', // 14px
    lineHeight: '1.25rem', // 20px
    '@media (max-width: 600px)': {
      padding: '0.5rem 0.6rem 0.5rem 0.6rem', // 12px 12px 12px 12px
      fontSize: '0.75rem', // 12px
      lineHeight: '1rem', // 16px
    },
  },
  profileContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginTop: '2rem',
    marginBottom: '0.5rem', // 20px
    '@media (max-width: 600px)': {
      marginTop: '3rem', // 20px
    },
  },
  header: {
    textAlign: 'center',
    color: '#fff',
    marginTop: '0.625rem', // 10px
  },
  image: {
    width: '4rem', // 80px
    height: '4rem', // 80px
    borderRadius: '50%',
  },
  chatContainer: {
    // marginTop: '0.3rem',
    flex: 1,
    overflowY: 'auto',
    padding: '0.625rem', // 10px
    borderRadius: '0.5rem', // 8px
    height: '57vh',
    /* 스크롤바 숨기기 */
    '&::-webkit-scrollbar': {
      display: 'none', // for Chrome, Safari, and Opera
    },
    '-ms-overflow-style': 'none', // for Internet Explorer and Edge
    'scrollbar-width': 'none', // for Firefox
  },
  inputContainer: {
    display: 'flex',
    alignItems: 'center',
    position: 'relative',
    padding: '0.625rem', // 10px
  },
  sendContainer: {
    display: 'flex',
    alignItems: 'center',
    position: 'relative',
    width: '100%',
  },
  input: {
    flex: 1,
    padding: '0.9rem 3rem 0.9rem 1.25rem',
    borderRadius: '4rem', // 
    border: '1px solid #555',
    backgroundColor: '#2d2e2f', // 배경색을 투명으로 설정하여 컨테이너와 일치시킴
    color: '#fff',
    outline: 'none',
  },
  sendButton: {
    display: 'flex',
    position: 'absolute', // 절대 위치로 설정하여 input 안에 배치
    right: '2%', // 10px
    width: '2rem', // 너비를 고정하여 동그란 모양으로 만듦
    height: '2rem', // 높이를 고정하여 동그란 모양으로 만듦
    borderRadius: '50%', // 완전한 원 모양을 위해 50%로 설정
    border: 'none',
    backgroundColor: '#1c1c1c',
    color: '#fff',
    cursor: 'pointer',
    alignItems: 'center',
    justifyContent: 'center', // 텍스트나 아이콘을 가운데 정렬
    fontSize: '1rem', // 24px
    '&.disabled': {
      backgroundColor: '#6c757d',
      cursor: 'not-allowed',
    },
    '@media (max-width: 600px)': {
      right: '5%', // 10px,
    },
  },
  sttButton: {
    marginLeft: '0.625rem', // 10px
    padding: '0.625rem 1.25rem', // 10px 20px
    borderRadius: '0.25rem', // 4px
    border: 'none',
    backgroundColor: '#28a745',
    color: '#fff',
    cursor: 'pointer',
    '&.recording': {
      backgroundColor: '#D24146',
      cursor: 'pointer',
    },
    '&.disabled': {
      backgroundColor: '#6c757d',
      cursor: 'not-allowed',
    },
  },
  voiceIcon: {
    width: '1.5rem', // 24px
    height: '1.5rem', // 24px
    color: '#fff',
  },
  bars: {
    display: 'flex',
    alignItems: 'flex-end',
    position: 'absolute', // 절대 위치로 설정하여 input 안에 배치
    right: '6%', // 10px
    justifyContent: 'center',
    width: '1.5rem', // 24px
    height: '1.5rem', // 24px
    '@media (max-width: 600px)': {
      right: '18%', // 10px
    },
    '@media (max-width: 960px)': {
      right: '9%', // 10px
    },
  },
  bar: {
    width: '0.1875rem', // 3px
    height: '50%',
    margin: '0 0.0625rem', // 0 1px
    transformOrigin: 'bottom',
    backgroundColor: '#00FF00',
    animation: '$bar 1s infinite ease-in-out',
    '&:nth-child(2)': {
      animationDelay: '0.1s',
    },
    '&:nth-child(3)': {
      animationDelay: '0.2s',
    },
  },
  '@keyframes bar': {
    '0%, 100%': {
      transform: 'scaleY(0.1)',
    },
    '50%': {
      transform: 'scaleY(1)',
    },
  },
}));
