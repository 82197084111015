import React, { useEffect } from 'react';
import { Route, Routes, useSearchParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { userActions } from './actions/user.actions';
import { appActions } from './actions/app.actions';

import Layout from './components/layout/Layout';

import GenerateMusicPage from './components/pages/GenerateMusicPage';
import GenerateTTSPage from './components/pages/GenerateTTSPage';
import MyVoicePage from './components/pages/MyVoicePage';
import Community from './components/pages/Community';
import TrainVoicePage from './components/pages/TrainVoicePage';
import TTSTrainVoicePage from './components/pages/TTSTrainVoicePage';
import TTSHistoryPage from './components/pages/TTSHistoryPage';
import customtheme from './theme';
import { ThemeProvider } from '@material-ui/core/styles';
import SubscriptionPage from './components/pages/SubscriptionPage';
import './i18n';
import { useTranslation } from 'react-i18next';
import VocalExtractorPage from './components/pages/VocalExtractorPage';
import FaqPage from './components/pages/FaqPage';
import ContactPage from './components/pages/ContactPage';
import { setUserCurrency, sendWelcomeEmail } from './services/page.services';
import TimeAgo from 'javascript-time-ago';
import EmailPreferencePage from './components/pages/EmailPreferencePage';
import CreatePersonaPage from './components/pages/CreatePersonaPage';
import PersonaCommunity from './components/pages/PersonaCommunity';
import PersonaHistory from './components/pages/PersonaHistory';
import ChatPage from './components/pages/ChatPage';
import en from 'javascript-time-ago/locale/en';

export default function App() {
  const { i18n, t } = useTranslation();
  const [searchParams, setSearchParams] = useSearchParams();
  const user = useSelector(state => state.user);
  const userId = user?.id;
  const { email, currency } = user;
  const dispatch = useDispatch();

  TimeAgo.addDefaultLocale(en);

  useEffect(() => {
    const defaultLanguage = localStorage.getItem('selectedLanguage') || navigator.language.split('-')[0];
    if (defaultLanguage === 'ko') i18n.changeLanguage('ko');
    else if (defaultLanguage === 'th') i18n.changeLanguage('th');
    else if (defaultLanguage === 'ja') i18n.changeLanguage('ja');
    else i18n.changeLanguage('en');
    localStorage.setItem('selectedLanguage', defaultLanguage);

    if (!userId) {
      userActions.getUser(dispatch);
    }
  }, []);

  useEffect(() => {
    if (userId && !currency) {
      (async () => {
        try {
          const updatedUser = await setUserCurrency();
          dispatch(userActions.setUserCurrency(updatedUser.currency));
        } catch (e) {
          dispatch(appActions.openMessageModal(t('modal.pageLoadFail'), true));
        }
      })();
    }
  }, [!userId]);

  useEffect(() => {
    const message = searchParams.get('message');
    const isNewUser = searchParams.get('new_user');

    if (message) {
      dispatch(appActions.openMessageModal(message));
      searchParams.delete('message');
    }

    if (isNewUser && email && currency) {
      (async () => {
        await sendWelcomeEmail(email, currency);
      })();
      searchParams.delete('new_user');
    }

    if (message || isNewUser) {
      setSearchParams(searchParams);
    }
  }, [searchParams, email, currency]);

  return (
    <ThemeProvider theme={customtheme}>
      <Layout>
        <Routes>
          <Route path="/" Component={Community} />
          <Route path="/train-voice" Component={TrainVoicePage} />
          <Route path="/generate-music" Component={GenerateMusicPage} />
          <Route path="/vocal-extract" Component={VocalExtractorPage} />
          <Route path="/my-voices" Component={MyVoicePage} />
          <Route path="/community" Component={Community} />
          <Route path="/tts-train-voice" Component={TTSTrainVoicePage} />
          <Route path="/tts-history" Component={TTSHistoryPage} />
          <Route path="/generate-tts" Component={GenerateTTSPage} />
          <Route path="/subscription" Component={SubscriptionPage} />
          <Route path="/faq" Component={FaqPage} />
          <Route path="/contact" Component={ContactPage} />
          <Route path="/email-preference" Component={EmailPreferencePage} />
          <Route path="/create-persona" Component={CreatePersonaPage} /> 
          <Route path="/persona-community" Component={PersonaCommunity} />
          <Route path="/persona-history" Component={PersonaHistory} />
          <Route path="/chat" Component={ChatPage} />

          <Route path="*" Component={GenerateMusicPage} />
        </Routes>
      </Layout>
    </ThemeProvider>
  );
}
