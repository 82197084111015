import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(theme => ({
  modal: {
    //display 고정되도록 설정
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    textAlign: 'center',
  },
}));
