import React from 'react';
import { Modal, Backdrop, Fade } from '@material-ui/core';
import { useStyles } from './LoadingModal.styles';

const LoadingModal = ({ open, message }) => {
  const classes = useStyles();

  return (
    <Modal
      open={open}
      className={classes.modal}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={open}>
        <div className={classes.paper}>
          <h2>Loading...</h2>
          <p>{message}</p>
        </div>
      </Fade>
    </Modal>
  );
};

export default LoadingModal;
