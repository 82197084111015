import * as momentTz from 'moment-timezone';
import getBlobDuration from 'get-blob-duration';
import JSZip from 'jszip';
import axios from 'axios';

export const getFilesDuration = async files => {
  let max = 0;
  for (let file of files) {
    max += await getBlobDuration(file);
  }
  return max;
};

export const zipModelFiles = async (id, files) => {
  const zip = new JSZip();
  const folderZip = zip.folder(id);
  files.forEach(f => folderZip.file(`${id}.${f.name.split('.')[f.name.split('.').length - 1]}`, f));
  return zip.generateAsync({ type: 'blob' });
};

export const zipFiles = async (id, files) => {
  const zip = new JSZip();
  const folderZip = zip.folder(id);
  files.forEach(f => folderZip.file(f.name, f));
  return await zip.generateAsync({ type: 'blob' });
};

export const convertS3ObjectToUrl = async s3Object => {
  const blob = new Blob([new Uint8Array(await s3Object.Body.transformToByteArray())], { type: 'audio/mp3' });
  return URL.createObjectURL(blob);
};

export const getTrainingDuration = (tier, pastDue) => {
  if (tier === 'ADMIN') {
    return { min: 1, max: 90 };
  } else if (tier === 'FREE' || pastDue) {
    return { min: 5, max: 10 };
  } else if (tier === 'PREMIUM') {
    return { min: 5, max: 90 };
  } else if (tier === 'PRO') {
    return { min: 5, max: 45 };
  } else if (tier === 'CUSTOM') {
    return { min: 5, max: 90 };
  } else {
    return { min: 5, max: 30 };
  }
};

export const getTTSTrainingDuration = (tier, pastDue) => {
  if (tier === 'ADMIN') {
    return { min: 15, max: 600 };
  } else if (tier === 'FREE' || pastDue) {
    return { min: 15, max: 600 };
  } else if (tier === 'PREMIUM') {
    return { min: 15, max: 600 };
  } else if (tier === 'PRO') {
    return { min: 15, max: 600 };
  } else if (tier === 'CUSTOM') {
    return { min: 15, max: 600 };
  } else {
    return { min: 15, max: 600 };
  }
};

export const getFormattedLanguage = () => {
  const selectedLanguage = localStorage.getItem('selectedLanguage');
  switch (selectedLanguage) {
    case 'ko':
      return '한국어';
    case 'th':
      return 'แบบไทย';
    case 'ja':
      return '日本語';
    default:
      return 'English';
  }
};

const SUPPORTED_CURRENCY = {
  KRW: 'krw',
  THB: 'thb',
  USD: 'usd',
  JPY: 'jpy',
};

const getCurrencyFromIp = async () => {
  const res = await axios.get('https://ipapi.co/json/');
  if (res?.status === 200) {
    const { currency } = res.data;
    if (currency == 'KRW') return SUPPORTED_CURRENCY.KRW;
    else if (currency === 'THB') return SUPPORTED_CURRENCY.THB;
    else if (currency === 'USD') return SUPPORTED_CURRENCY.USD;
    else if (currency === 'JPY') return SUPPORTED_CURRENCY.JPY;
  }
  return null;
};

const getCurrencyFromMoment = () => {
  const guess = momentTz.tz.guess();
  const krZones = momentTz.tz.zonesForCountry('KR');
  const thZones = momentTz.tz.zonesForCountry('TH');
  const usZones = momentTz.tz.zonesForCountry('US');
  if (krZones.includes(guess)) return SUPPORTED_CURRENCY.KRW;
  else if (thZones.includes(guess)) return SUPPORTED_CURRENCY.THB;
  else if (usZones.includes(guess)) return SUPPORTED_CURRENCY.USD;
  return null;
};

const getCurrencyFromDefaultLangugage = () => {
  const defaultLanguage = navigator.language.split('-')[0];
  if (defaultLanguage === 'ko') return SUPPORTED_CURRENCY.KRW;
  else if (defaultLanguage === 'th') return SUPPORTED_CURRENCY.THB;
  else if (defaultLanguage === 'ja') return SUPPORTED_CURRENCY.JPY;
  else return SUPPORTED_CURRENCY.USD;
};

export const getCurrency = async () => {
  const currencyFromIp = await getCurrencyFromIp();
  const currencyFromMoment = getCurrencyFromMoment();
  const currencyFromLangugage = getCurrencyFromDefaultLangugage();
  return currencyFromIp || currencyFromMoment || currencyFromLangugage;
};
