import React from 'react';
import PropTypes from 'prop-types';
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Button } from '@material-ui/core';
import { useStyles } from './deleteModal.styles';
import { useEffect } from 'react';

const DeleteModal = ({ open, onClose, onDelete }) => {
  const classes = useStyles();
  useEffect(() => {
    const body = document.querySelector('body');

    if (open) {
      body.setAttribute('aria-hidden', 'true');  // 모달이 열릴 때 body에 aria-hidden 적용
    } else {
      body.removeAttribute('aria-hidden'); // 모달이 닫힐 때 제거
    }

    return () => {
      body.removeAttribute('aria-hidden'); // 컴포넌트 언마운트 시에도 속성 제거
    };
  }, [open]);
  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      classes={{ paper: classes.dialogPaper }}
    >
      <DialogTitle id="alert-dialog-title" className={classes.title}>
        채팅 삭제
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description" className={classes.contentText}>
          삭제된 내용은 복구할 수 없어요.
          이 채팅방을 삭제하시겠어요?
        </DialogContentText>
      </DialogContent>
      <DialogActions className={classes.actions}>
        <Button onClick={onClose} className={classes.cancelButton}>
          취소
        </Button>
        <Button onClick={onDelete} className={classes.deleteButton} autoFocus>
          삭제
        </Button>
      </DialogActions>
    </Dialog>
  );
};

DeleteModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
};

export default DeleteModal;
