import React, { useEffect, useState, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useStyles } from './PersonaCommunity.style.js';
import moment from 'moment';
import _ from 'lodash';
import InfiniteScroll from 'react-infinite-scroll-component';
import { appActions } from '../../actions/app.actions.js';
import { fetchAssistantsCommunityItems, searchAssistantsAndThreads } from '../../services/page.services';
import { Grid } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import PersonaHistory from './PersonaHistory';

function Community() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const user = useSelector(state => state.user);
  const userId = user?.id;

  const [data, setData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [userThreads, setUserThreads] = useState([]);
  const [initialThreads, setInitialThreads] = useState([]);
  const [communityQuery, setCommunityQuery] = useState('');
  const [users, setUsers] = useState([]);
  const [selectedSortingMethod, setSelectedSortingMethod] = useState('trending');

  const handleSortChange = event => {
    setSelectedSortingMethod(event.target.value);
  };

  useEffect(() => {
    initData();
  }, [selectedSortingMethod]);

  const initData = async () => {
    try {
      const response = await fetchAssistantsCommunityItems(selectedSortingMethod);
      setData(response);
      setFilteredData(response);
      const threadsResponse = await searchAssistantsAndThreads(''); // Empty query to get initial threads
      setUserThreads(threadsResponse.userThreads);
      setInitialThreads(threadsResponse.userThreads);
      const usersData = response.reduce((acc, item) => {
        acc[item.user.id] = item.user;
        return acc;
      }, {});
      setUsers(usersData);
    } catch (err) {
      if (err.name !== 'CanceledError') {
        dispatch(appActions.openMessageModal(t('historyTab.modal.error'), false, true));
      }
    }
  };

  const debouncedHandleSearch = useCallback(
    _.debounce(async query => {
      if (query) {
        try {
          const searchResults = await searchAssistantsAndThreads(query);
          setUserThreads(searchResults.userThreads);
          setFilteredData(searchResults.trendingAssistants);
        } catch (err) {
          console.error('Error searching assistants and threads:', err);
        }
      } else {
        setUserThreads(initialThreads); // Reset to initial threads if no query
        initData();
      }
    }, 500),
    [initialThreads]
  );

  useEffect(() => {
    debouncedHandleSearch(communityQuery);
  }, [communityQuery, debouncedHandleSearch]);

  const handleCreate = async () => {
    navigate('/create-persona');
  };

  const classes = useStyles();

  const findUserName = userId => {
    const user = users[userId];
    return user ? user.name : 'Unknown';
  };

  const handleCommunityQueryChange = event => {
    setCommunityQuery(event.target.value);
  };

  return (
    <div className={classes.main}>
      <div className={classes.pageTitle}>{t('personaCommunityTab.title')}</div>

      <Grid container className={classes.searchButtonContainer}>
        <Grid item xs={12} sm={6} md={7} className={classes.searchbarContainer}>
          <input
            className={classes.searchbar}
            placeholder={t('personaCommunityTab.searchbar')}
            value={communityQuery}
            onChange={handleCommunityQueryChange}
            style={{ width: '100%' }}
          />
          <SearchIcon className={classes.searchIcon} />
        </Grid>

        <Grid item xs={12} sm={3} md={2} container justifyContent="flex-end">
          <div className={classes.button} onClick={handleCreate}>
            {/* <img src='/create.svg' alt="Create Icon" className={classes.buttonIcon} /> */}
            {t('personaCommunityTab.create')}
          </div>
        </Grid>
      </Grid>

      {userThreads.length > 0 && <PersonaHistory userThreads={userThreads} />}

      <Grid
        container
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        className={classes.recentContainer}
      >
        <Grid item className={classes.titleContainer}>
          <div className={classes.pageSmallTitle}>
            {communityQuery.length > 0 ? t('personaCommunityTab.searchResult') : t('personaCommunityTab.sortOptions.0')}
          </div>
        </Grid>

        <Grid container item xs={4} sm={4} md={4} display="flex" justifyContent="flex-end">
          <FormControl size="small" className={classes.sortSelect}>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={selectedSortingMethod}
              onChange={handleSortChange}
            >
              <MenuItem value={'trending'}>{t('personaCommunityTab.simpleSortOptions.0')}</MenuItem>
              <MenuItem value={'latest'}>{t('personaCommunityTab.simpleSortOptions.1')}</MenuItem>
              <MenuItem value={'name'}>{t('personaCommunityTab.simpleSortOptions.2')}</MenuItem>
            </Select>
          </FormControl>
        </Grid>
      </Grid>

      <InfiniteScroll
        className={classes.infiniteScroll}
        dataLength={filteredData.length}
        loader={
          <div className={classes.pagination} key={0}>
            {t('historyTab.pagination.loader')}
          </div>
        }
      >
        <Grid container className={classes.cardGrid}>
          {filteredData.map(
            ({ id, name, userId, createdAt, updatedAt, image, anonymous, ttsTrainingId, description }) => {
              const userName = findUserName(userId);
              return (
                <Grid
                  className={classes.card}
                  item
                  container
                  key={id}
                  onClick={() => {
                    dispatch(
                      appActions.selectCommunityItem({
                        id,
                        label: name,
                        userId,
                        createdAt,
                        updatedAt,
                        image,
                        anonymous,
                        ttsTrainingId,
                        description,
                      })
                    );
                    navigate('/chat', {
                      state: {
                        modelName: name,
                        image: image,
                        assistantId: id,
                        ttsTrainingId: ttsTrainingId,
                      },
                    });
                  }}
                >
                  {image && (
                    <Grid className={classes.imgContainer} item xs="auto" sm={3} md={3}>
                      <img className={classes.coverImg} src={image} alt="cover-img" />
                    </Grid>
                  )}
                  <Grid container className={classes.cardSecondColumn} item xs sm={9} md={9}>
                    <Grid container item>
                      <Grid container className={classes.cardLabelContainer} item xs={10} sm={10} md={10}>
                        <div className={classes.cardLabel}>{name}</div>
                      </Grid>
                    </Grid>
                    <Grid container item>
                      {<div className={classes.cardDescription}>{description}</div>}
                    </Grid>
                    {/* <Grid container item>
                      {<div className={classes.cardLabelUsername}>{userName}</div>}
                    </Grid>
                    <Grid container item>
                      <div className={classes.cardCreatedAt}>{moment(createdAt).fromNow()}</div>
                    </Grid> */}
                  </Grid>
                </Grid>
              );
            }
          )}
        </Grid>
      </InfiniteScroll>
    </div>
  );
}

export default Community;
